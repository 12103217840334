import {
  map, catchError, mergeMap, takeUntil, debounceTime,
} from 'rxjs/operators';
import { stringify } from 'query-string';
import { ofType } from 'redux-observable';
import { ajax } from 'rxjs/ajax';
import { of } from 'rxjs';
import { apiCall } from '../../../utils';

export const GENERIC_ACTION = 'GENERIC_ACTION'

const INITIAL_STATE = {
  data: {},
  loading: false,
  error: false,
  flag: false,
}

const GET_AUTOCOMPLETE_VALUES = 'GET_AUTOCOMPLETE_VALUES';
const GET_AUTOCOMPLETE_VALUES_RESET = 'GET_AUTOCOMPLETE_VALUES_RESET';
const GET_AUTOCOMPLETE_VALUES_CANCEL = 'GET_AUTOCOMPLETE_VALUES_CANCEL';
const GET_AUTOCOMPLETE_VALUES_SUCCESS = 'GET_AUTOCOMPLETE_VALUES_SUCCESS';
const GET_AUTOCOMPLETE_VALUES_FAILURE = 'GET_AUTOCOMPLETE_VALUES_FAILURE';

// Auto Complete cancel action
export const getAutocompleteValuesCancelAction = () => ({
  type: GET_AUTOCOMPLETE_VALUES_CANCEL,
})

// Auto Complete action
export const getAutocompleteValuesAction = (payload) => ({
  type: GET_AUTOCOMPLETE_VALUES,
  payload,
})

export const getAutocompleteValuesResetAction = (payload) => ({
  type: GET_AUTOCOMPLETE_VALUES_RESET,
  payload,
})

// Auto Complete Success action
const getAutocompleteValuesSuccess = (payload) => ({
  type: GET_AUTOCOMPLETE_VALUES_SUCCESS,
  payload,
})

export const getAutocompleteValuesEpic = (actions$) => actions$.pipe(
  ofType(GET_AUTOCOMPLETE_VALUES),
  debounceTime(500),
  mergeMap((action) => ajax(apiCall(`${process.env.apiUrl}v0/search/autocomplete?${stringify(action.payload)}`, 'get')).pipe(
    map((res) => getAutocompleteValuesSuccess(res)),
    takeUntil(actions$.pipe(ofType(GET_AUTOCOMPLETE_VALUES_CANCEL))),
    catchError((err) => of({
      type: GET_AUTOCOMPLETE_VALUES_FAILURE,
      payload: err,
    })),
  )),
);

export function getAutocompleteValuesReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case GET_AUTOCOMPLETE_VALUES: {
      return {
        data: {},
        loading: true,
        flag: false,
        error: false,
      }
    }
    case GET_AUTOCOMPLETE_VALUES_SUCCESS: {
      return {
        data: action.payload.response.data,
        loading: false,
        flag: true,
        error: false,
      }
    }
    case GET_AUTOCOMPLETE_VALUES_RESET: {
      return {
        data: {},
        loading: false,
        flag: false,
        error: false,
      }
    }
    case GET_AUTOCOMPLETE_VALUES_FAILURE: {
      return {
        data: {},
        loading: false,
        flag: false,
        error: true,
      }
    }
    default:
      return state
  }
}
