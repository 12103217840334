import { GENERIC_ACTION } from '../SearchResults/logic'

const GET_STATE_CANCER_FILTER_DATA = 'GET_STATE_CANCER_FILTER_DATA'
const GET_STATE_CANCER_FILTER_DATA_SUCCESS = 'GET_STATE_CANCER_FILTER_DATA_SUCCESS'
const GET_STATE_CANCER_FILTER_DATA_RESET = 'GET_STATE_CANCER_FILTER_DATA_RESET'
const GET_STATE_CANCER_FILTER_DATA_FAILURE = 'GET_STATE_CANCER_FILTER_DATA_FAILURE'

const GET_STATE_CANCER_DATA = 'GET_STATE_CANCER_DATA'
const GET_STATE_CANCER_DATA_SUCCESS = 'GET_STATE_CANCER_DATA_SUCCESS'
const GET_STATE_CANCER_DATA_RESET = 'GET_STATE_CANCER_DATA_RESET'
const GET_STATE_CANCER_DATA_FAILURE = 'GET_STATE_CANCER_DATA_FAILURE'

const INITIAL_STATE = {
  data: {},
  loading: false,
  error: false,
  flag: false,
}
// filter
export const getStateCancerFilterDataAction = (payload, meta = {}) => ({
  type: GENERIC_ACTION,
  payload,
  meta: {
    actionType: GET_STATE_CANCER_FILTER_DATA,
    url: `${process.env.apiUrl}v0/search/stateCancerDataFilters`,
    method: 'GET',
    auth: true,
    log: false, // for console
    ...meta,
  },
  showNotification: false, // show notification on success
  hideNotification: false, // hide notification on error
})

export const getStateCancerFilterResetAction = () => ({
  type: GET_STATE_CANCER_FILTER_DATA_RESET,
})

export function getStateCancerFilterReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case GET_STATE_CANCER_FILTER_DATA: {
      return {
        data: {},
        loading: true,
        flag: false,
        error: false,
      }
    }
    case GET_STATE_CANCER_FILTER_DATA_SUCCESS: {
      console.log('action===>', action)
      return {
        data: action.payload,
        loading: false,
        type: action.meta.type,
        flag: true,
        error: false,
      }
    }
    case GET_STATE_CANCER_FILTER_DATA_RESET: {
      return {
        data: {},
        loading: false,
        flag: false,
        error: false,
      }
    }
    case GET_STATE_CANCER_FILTER_DATA_FAILURE: {
      return {
        data: {},
        loading: false,
        flag: false,
        error: true,
      }
    }
    default:
      return state
  }
}

// data
export const getStateCancerDataAction = (payload, meta = {}) => ({
  type: GENERIC_ACTION,
  payload,
  meta: {
    actionType: GET_STATE_CANCER_DATA,
    url: `${process.env.apiUrl}v0/search/stateCancerData`,
    method: 'GET',
    auth: true,
    log: false, // for console
    ...meta,
  },
  showNotification: false, // show notification on success
  hideNotification: false, // hide notification on error
})

export const getStateCancerResetAction = () => ({
  type: GET_STATE_CANCER_DATA_RESET,
})

export function getStateCancerReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case GET_STATE_CANCER_DATA: {
      return {
        data: {},
        loading: true,
        flag: false,
        error: false,
      }
    }
    case GET_STATE_CANCER_DATA_SUCCESS: {
      return {
        data: action.payload,
        loading: false,
        flag: true,
        error: false,
      }
    }
    case GET_STATE_CANCER_DATA_RESET: {
      return {
        data: {},
        loading: false,
        flag: false,
        error: false,
      }
    }
    case GET_STATE_CANCER_DATA_FAILURE: {
      return {
        data: {},
        loading: false,
        flag: false,
        error: true,
      }
    }
    default:
      return state
  }
}
