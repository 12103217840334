import { stringify } from 'query-string';
import { GENERIC_ACTION } from '../SearchResults/logic'

const GET_SHARED_PROJECTS_DATA = 'GET_SHARED_PROJECTS_DATA'
const GET_SHARED_PROJECTS_DATA_MORE = 'GET_SHARED_PROJECTS_DATA_MORE'
const GET_SHARED_PROJECTS_DATA_MORE_SUCCESS = 'GET_SHARED_PROJECTS_DATA_MORE_SUCCESS'
const GET_SHARED_PROJECTS_DATA_SUCCESS = 'GET_SHARED_PROJECTS_DATA_SUCCESS'
const GET_SHARED_PROJECTS_DATA_FAILURE = 'GET_SHARED_PROJECTS_DATA_FAILURE'

const REVIEW_ANALYSIS = 'REVIEW_ANALYSIS'
const REVIEW_ANALYSIS_SUCCESS = 'REVIEW_ANALYSIS_SUCCESS'
const REVIEW_ANALYSIS_FAILURE = 'REVIEW_ANALYSIS_DATA_FAILURE'

const REVIEW_PROJECT = 'REVIEW_PROJECT'
const REVIEW_PROJECT_SUCCESS = 'REVIEW_PROJECT_SUCCESS'
const REVIEW_PROJECT_FAILURE = 'REVIEW_PROJECT_DATA_FAILURE'

const INITIAL_STATE = {
  data: {},
  loading: false,
  error: false,
  flag: false,
}

export const getSharedProjectsDataAction = (payload, meta = {}) => ({
  type: GENERIC_ACTION,
  payload,
  meta: {
    actionType: GET_SHARED_PROJECTS_DATA,
    url: `${process.env.apiUrl}v0/projects/shareProject`,
    method: 'get',
    auth: true,
    // customHeaders: { 'Content-Type': undefined },
    log: false, // for console
    ...meta,
  },
  showNotification: false, // show notification on success
  hideNotification: false, // hide notification on error
})

export const getSharedProjectsDataMoreAction = (payload, meta = {}) => ({
  type: GENERIC_ACTION,
  payload,
  meta: {
    actionType: GET_SHARED_PROJECTS_DATA_MORE,
    url: `${process.env.apiUrl}v0/projects/shareProject`,
    method: 'get',
    auth: true,
    // customHeaders: { 'Content-Type': undefined },
    log: false, // for console
    ...meta,
  },
  showNotification: false, // show notification on success
  hideNotification: false, // hide notification on error
})

export function getSharedProjectsDataReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case GET_SHARED_PROJECTS_DATA: {
      return {
        data: {},
        loading: true,
        flag: false,
        error: false,
      }
    }
    case GET_SHARED_PROJECTS_DATA_SUCCESS: {
      return {
        data: action.payload,
        loading: false,
        flag: true,
        error: false,
      }
    }

    case GET_SHARED_PROJECTS_DATA_MORE_SUCCESS: {
      return {
        data: {
          data: [
            ...state.data.data,
            ...action.payload.data,
          ],
          total: action.payload.total,
        },
        loading: false,
        flag: true,
        error: false,
      }
    }

    case GET_SHARED_PROJECTS_DATA_FAILURE: {
      return {
        data: {},
        loading: false,
        flag: false,
        error: true,
      }
    }
    default:
      return state
  }
}

export const reviewAnalysisAction = (payload, meta = {}) => ({
  type: GENERIC_ACTION,
  payload: payload.body,
  meta: {
    actionType: REVIEW_ANALYSIS,
    url: `${process.env.apiUrl}v0/analyses/analysisReviewCompleted?${stringify(payload.params)}`,
    method: 'PUT',
    auth: true,
    // customHeaders: { 'Content-Type': undefined },
    log: false, // for console
    ...meta,
  },
  showNotification: false, // show notification on success
  hideNotification: false, // hide notification on error
})

export function reviewAnalysisReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case REVIEW_ANALYSIS: {
      return {
        data: {},
        loading: true,
        flag: false,
        error: false,
      }
    }
    case REVIEW_ANALYSIS_SUCCESS: {
      return {
        data: action.payload,
        loading: false,
        flag: true,
        error: false,
      }
    }
    case REVIEW_ANALYSIS_FAILURE: {
      return {
        data: {},
        loading: false,
        flag: false,
        error: true,
      }
    }
    default:
      return state
  }
}

export const reviewProjectAction = (payload, meta = {}) => ({
  type: GENERIC_ACTION,
  payload: payload.body,
  meta: {
    actionType: REVIEW_PROJECT,
    // stringify
    url: `${process.env.apiUrl}v0/projects/projectReviewCompleted?${stringify(payload.params)}`,
    method: 'PUT',
    auth: true,
    // customHeaders: { 'Content-Type': undefined },
    log: false, // for console
    ...meta,
  },
  showNotification: false, // show notification on success
  hideNotification: false, // hide notification on error
})

export function reviewProjectReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case REVIEW_PROJECT: {
      return {
        data: {},
        loading: true,
        flag: false,
        error: false,
      }
    }
    case REVIEW_PROJECT_SUCCESS: {
      return {
        data: action.payload,
        loading: false,
        flag: true,
        error: false,
      }
    }
    case REVIEW_PROJECT_FAILURE: {
      return {
        data: {},
        loading: false,
        flag: false,
        error: true,
      }
    }
    default:
      return state
  }
}
