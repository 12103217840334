import { GENERIC_ACTION } from '../SearchResults/logic'

const GET_SEARCH_HISTORY = 'GET_SEARCH_HISTORY'
const GET_SEARCH_HISTORY_MORE = 'GET_SEARCH_HISTORY_MORE'
const GET_SEARCH_HISTORY_SUCCESS = 'GET_SEARCH_HISTORY_SUCCESS'
const GET_SEARCH_HISTORY_MORE_SUCCESS = 'GET_SEARCH_HISTORY_MORE_SUCCESS'
const GET_SEARCH_HISTORY_RESET = 'GET_SEARCH_HISTORY_RESET'
const GET_SEARCH_HISTORY_FAILURE = 'GET_SEARCH_HISTORY_FAILURE'

const CREATE_SEARCH_HISTORY = 'CREATE_SEARCH_HISTORY';
const CREATE_SEARCH_HISTORY_RESET = 'CREATE_SEARCH_HISTORY_RESET';
const CREATE_SEARCH_HISTORY_SUCCESS = 'CREATE_SEARCH_HISTORY_SUCCESS';
const CREATE_SEARCH_HISTORY_FAILURE = 'CREATE_SEARCH_HISTORY_FAILURE';

const INITIAL_STATE = {
  data: {},
  loading: false,
  error: false,
  flag: false,
}

export const getSearchHistoryAction = (payload, meta = {}) => ({
  type: GENERIC_ACTION,
  payload,
  meta: {
    actionType: GET_SEARCH_HISTORY,
    url: `${process.env.apiUrl}v0/search/searchHistory`,
    method: 'GET',
    auth: true,
    // customHeaders: { 'Content-Type': undefined },
    log: false, // for console
    ...meta,
  },
  showNotification: false, // show notification on success
  hideNotification: false, // hide notification on error
})

export const createSearchHistoryAction = (payload, meta = {}) => ({
  type: GENERIC_ACTION,
  payload,
  meta: {
    actionType: CREATE_SEARCH_HISTORY,
    url: `${process.env.apiUrl}v0/search/searchHistory`,
    method: 'POST',
    auth: true,
    // customHeaders: { 'Content-Type': undefined },
    log: false, // for console
    ...meta,
  },
  showNotification: false, // show notification on success
  hideNotification: false, // hide notification on error
})

export const getSearchHistoryMoreAction = (payload, meta = {}) => ({
  type: GENERIC_ACTION,
  payload,
  meta: {
    actionType: GET_SEARCH_HISTORY_MORE,
    url: `${process.env.apiUrl}v0/search/searchHistory`,
    method: 'GET',
    auth: true,
    // customHeaders: { 'Content-Type': undefined },
    log: false, // for console
    ...meta,
  },
  showNotification: false, // show notification on success
  hideNotification: false, // hide notification on error
})

export const getSearchHistoryResetAction = () => ({
  type: GET_SEARCH_HISTORY_RESET,
})

export const createSearchHistoryResetAction = () => ({
  type: CREATE_SEARCH_HISTORY_RESET,
})

export function getSearchHistoryReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case GET_SEARCH_HISTORY: {
      return {
        data: {},
        loading: true,
        flag: false,
        error: false,
      }
    }
    case GET_SEARCH_HISTORY_SUCCESS: {
      return {
        data: action.payload,
        loading: false,
        flag: true,
        error: false,
      }
    }
    case GET_SEARCH_HISTORY_MORE_SUCCESS: {
      return {
        data: {
          data: [
            ...state.data.data,
            ...action.payload.data,
          ],
          total: action.payload.total,
        },
        loading: false,
        flag: true,
        error: false,
      }
    }
    case GET_SEARCH_HISTORY_RESET: {
      return {
        data: {},
        loading: false,
        flag: false,
        error: false,
      }
    }
    case GET_SEARCH_HISTORY_FAILURE: {
      return {
        data: {},
        loading: false,
        flag: false,
        error: true,
      }
    }
    default:
      return state
  }
}

export function createSearchHistoryReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case CREATE_SEARCH_HISTORY: {
      return {
        data: {},
        loading: true,
        flag: false,
        error: false,
      }
    }

    case CREATE_SEARCH_HISTORY_SUCCESS: {
      return {
        data: action.payload,
        loading: false,
        flag: true,
        error: false,
      }
    }

    case CREATE_SEARCH_HISTORY_RESET: {
      return {
        data: {},
        loading: false,
        flag: false,
        error: false,
      }
    }

    case CREATE_SEARCH_HISTORY_FAILURE: {
      return {
        data: {},
        loading: false,
        flag: false,
        error: true,
      }
    }

    default:
      return state
  }
}
