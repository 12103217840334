import { Tooltip } from 'antd';
import React from 'react';
import { stringify } from 'query-string';
import ReadMore from '../../../common/components/ReadMore';
import { getConcatenatedString, getFormattedDate } from '../../../utils';

const getTemplate = (type, itm, linkFlag, parent_flag) => {
  switch (type) {
    case 'SITE_Parent':
    case 'SITE': {
      return (
        <>
          <div className="search-dropdown-result-list-card-title">
            <span className="link">{parent_flag ? 'Parent Center' : 'Center'}</span>
            <span className="title-divider">|</span>
            <span className="title-in">{itm.name}</span>
          </div>
          <div className="combine-div">
            <div className="search-dropdown-result-list-card-content">
              <div className="content-head">Tier:&nbsp;</div>
              <div className="content-value">{itm.tier}</div>
            </div>
            <div className="search-dropdown-result-list-card-content">
              <div className="content-head">Alliance:&nbsp;</div>
              <div className="content-value">{itm.alliance ? 'Yes' : 'No'}</div>
            </div>
          </div>
          <div className="search-dropdown-result-list-card-content single-line-data">
            <div className="content-head">Indications:</div>
            <Tooltip
              placement="bottom"
              title={itm.indications && itm.indications.length ? itm.indications.join('; ') : ''}
            >
              <span className="content-value">
                <ReadMore type="text" limit={100}>
                  {itm.indications && itm.indications.length ? itm.indications.join('; ') : ''}
                </ReadMore>
              </span>
            </Tooltip>
            {/* <div className="content-value">
              {itm.indications && itm.indications.length ? itm.indications.join(', ') : ''}
            </div> */}
          </div>
        </>
      );
    }
    case 'PI': {
      return (
        <>
          <div className="search-dropdown-result-list-card-title">
            <span className="link">Investigator</span>
            <span className="title-divider">|</span>
            {linkFlag
              ? (
                <a
                  className="title-in"
                  title={itm.name}
                  target="_blank"
                  href={`/search/investigatorprofile?${stringify({
                    query: itm.name,
                    id: itm.id,
                    type: 'PI',
                  })}`}
                  style={{ color: '#8A0050' }}
                  rel="noreferrer noopener"
                >
                  {itm.name}
                </a>
              )
              : (
                <span className="title-in">{itm.name}</span>
              )}
          </div>
          <div className="combine-div">
            <div className="search-dropdown-result-list-card-content">
              <div className="content-head">Tier:&nbsp;</div>
              <div className="content-value">{itm.tier}</div>
            </div>
            <div className="search-dropdown-result-list-card-content">
              <div className="content-head">Primary Center:&nbsp;</div>
              <div className="content-value" title={itm.affiliated_site}>
                <ReadMore type="text" limit={75}>
                  {itm.affiliated_site
                    ? itm.affiliated_site
                    : '-'}
                </ReadMore>
              </div>
            </div>
          </div>
          <div className="search-dropdown-result-list-card-content single-line-data">
            <div className="content-head">Indications:</div>
            <Tooltip
              placement="bottom"
              title={itm.indications && itm.indications.length ? itm.indications.join('; ') : ''}
            >
              <span className="content-value">
                <ReadMore type="text" limit={100}>
                  {itm.indications && itm.indications.length ? itm.indications.join('; ') : ''}
                </ReadMore>
              </span>
            </Tooltip>
          </div>
        </>
      );
    }
    case 'Project': {
      return (
        <>
          <div className="combine-div">
            {/* Project, Owner, Indication & Sponsors (pois) data section  */}
            <div className="search-data-pois">
              <div className="search-dropdown-result-list-card-title">
                <span className="link">Project</span>
                <span className="title-divider">|</span>
                <span className="title-in">{itm.name}</span>
              </div>
              <div className="combine-div">
                <div className="search-dropdown-result-list-card-content">
                  <div className="content-head">Owner:&nbsp;</div>
                  <div className="content-value">{itm.user_name}</div>
                </div>
              </div>
              <div className="combine-div flexwrap">
                <div className="search-dropdown-result-list-card-content">
                  <div className="content-head">Indications:</div>
                  <Tooltip
                    placement="bottom"
                    title={itm.indications && itm.indications.length ? itm.indications.join('; ') : ''}
                  >
                    <span className="content-value">
                      <ReadMore type="text" limit={100}>
                        {itm.indications && itm.indications.length ? itm.indications.join('; ') : ''}
                      </ReadMore>
                    </span>
                  </Tooltip>
                </div>
                <div className="search-dropdown-result-list-card-content">
                  <div className="content-head">Sponsors:</div>
                  <Tooltip
                    placement="bottom"
                    title={itm.sponsors && itm.sponsors.length ? getConcatenatedString(itm.sponsors, 'name') : ''}
                  >
                    <span className="content-value">
                      <ReadMore type="text" limit={100}>
                        {itm.sponsors && itm.sponsors.length ? getConcatenatedString(itm.sponsors, 'name') : ''}
                      </ReadMore>
                    </span>
                  </Tooltip>
                </div>
              </div>
            </div>
            {/* Created on & Modified (cm) on data section  */}
            <div className="search-data-cm">
              <div className="search-dropdown-result-list-card-content">
                <div className="content-head">Created on:</div>
                <div className="content-value">{getFormattedDate(itm.created_on)}</div>
              </div>
              <div className="search-dropdown-result-list-card-content">
                <div className="content-head">Modified on:</div>
                <div className="content-value">{getFormattedDate(itm.modification_activity.last_modified_on)}</div>
              </div>
            </div>
          </div>
        </>
      );
    }
    default:
      return (
        <>
          <div className="search-dropdown-result-list-card-title">
            <span className="link">{itm.type}</span>
            <span className="title-divider">|</span>
            <span className="title-in">{itm.name}</span>
          </div>
          <div className="combine-div">
            <div className="search-dropdown-result-list-card-content">
              <div className="content-head">Tier:&nbsp;</div>
              <div className="content-value">{itm.tier}</div>
            </div>
            <div className="search-dropdown-result-list-card-content">
              <div className="content-head">Alliance:&nbsp;</div>
              <div className="content-value">{itm.alliance ? 'Yes' : 'No'}</div>
            </div>
          </div>
          <div className="search-dropdown-result-list-card-content single-line-data">
            <div className="content-head">Indications:</div>
            <Tooltip
              placement="bottom"
              title={itm.indications && itm.indications.length ? itm.indications.join('; ') : ''}
            >
              <span className="content-value">
                <ReadMore type="text" limit={100}>
                  {itm.indications && itm.indications.length ? itm.indications.join('; ') : ''}
                </ReadMore>
              </span>
            </Tooltip>
          </div>
        </>
      );
  }
};

export { getTemplate };
