export const GENERIC_ACTION = 'GENERIC_ACTION'

const GET_SEARCH_RESULT_FILTERS_FOR_TABS = 'GET_SEARCH_RESULT_FILTERS_FOR_TABS';
const GET_SEARCH_RESULT_FILTERS_FOR_TABS_SUCCESS = 'GET_SEARCH_RESULT_FILTERS_FOR_TABS_SUCCESS';
const GET_SEARCH_RESULT_FILTERS_FOR_TABS_RESET = 'GET_SEARCH_RESULT_FILTERS_FOR_TABS_RESET';
const GET_SEARCH_RESULT_FILTERS_FOR_TABS_FAILURE = 'GET_SEARCH_RESULT_FILTERS_FOR_TABS_FAILURE';

const INITIAL_STATE = {
  data: {},
  loading: false,
  error: false,
  flag: false,
}

export const getSearchResultFilterForTabAction = (payload, meta = {}) => ({
  type: GENERIC_ACTION,
  payload,
  meta: {
    actionType: GET_SEARCH_RESULT_FILTERS_FOR_TABS,
    url: `${process.env.apiUrl}v0/search/searchfilters`,
    method: 'get',
    auth: true,
    log: false,
    ...meta,
  },
  showNotification: false,
  hideNotification: false,
})

export const getSearchResultFilterForTabResetAction = () => ({
  type: GET_SEARCH_RESULT_FILTERS_FOR_TABS_RESET,
})

export function getSearchResultFilterForTabReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case GET_SEARCH_RESULT_FILTERS_FOR_TABS: {
      return {
        data: {
          ...state.data,
        },
        loading: true,
        flag: false,
        error: false,
      }
    }
    case GET_SEARCH_RESULT_FILTERS_FOR_TABS_SUCCESS: {
      if (action.meta.filter_type) {
        return {
          data: {
            ...state.data, [action.meta.filter_type]: action.payload,
          },
          loading: false,
          flag: true,
          error: false,
        }
      }
      return {
        data: action.payload,
        loading: false,
        flag: true,
        error: false,
      }
    }
    case GET_SEARCH_RESULT_FILTERS_FOR_TABS_RESET: {
      return {
        data: {},
        loading: false,
        flag: false,
        error: false,
      }
    }
    case GET_SEARCH_RESULT_FILTERS_FOR_TABS_FAILURE: {
      return {
        data: {},
        loading: false,
        flag: false,
        error: true,
      }
    }
    default:
      return state
  }
}
