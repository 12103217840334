import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Select, Radio, Button, DatePicker,
} from 'antd';
import { getSearchResultFilterForTabAction, getSearchResultFilterForTabResetAction } from './searchResultFilterForTabLogic';
import Loader from '../../../common/components/Loader';

const { Option } = Select;
const apiBasedFilters = {
  investigators: ['indications', 'countries', 'sources', 'primary_center_types', 'compliance', 'alliance_type'],
  centers: ['indications', 'countries', 'sources', 'center_type', 'alliance_type', 'department'],
  parent_centers: ['indications', 'countries', 'sources', 'center_type', 'department'],
  clinical_trials: ['indications', 'phase', 'status', 'sponsor'],
};

const SearchResultFilters = (props) => {
  const dispatch = useDispatch();
  const [selectedFilters, setSelectedFilters] = useState({
    alliance: undefined,
    enrolled_count: [0, 100000],
    industry_studies: [0, 1000],
    quality_rating: undefined,
    tier: undefined,
    date: undefined,
  });
  const [startDateSelected, setStartDateSelected] = useState(undefined)
  const [endDateSelected, setEndDateSelected] = useState(undefined)
  const [dateSelected, setDateSelected] = useState({
    startDate: '',
    endDate: '',
  })
  const searchResultFilterForTabResponse = useSelector((state) => state.getSearchResultFilterForTabResponse)

  useEffect(() => {
    setSelectedFilters(props.appliedFilters);
  }, [JSON.stringify(props.appliedFilters)])

  useEffect(() => {
    return () => {
      dispatch(getSearchResultFilterForTabResetAction());
    };
  }, []);

  useEffect(() => {
    dispatch(getSearchResultFilterForTabResetAction());
    apiBasedFilters[props.dataset].map((type) => {
      dispatch(getSearchResultFilterForTabAction({
        dataset: props.dataset === 'parent_centers' ? 'centers' : props.dataset,
        type,
        query: props.query,
      }, {
        filter_type: type,
        key: type,
      }))
      return null;
    })
  }, [props.dataset]);

  const onRadioFilterChange = (e, key) => {
    const tempFilters = { ...selectedFilters };
    tempFilters[key] = e.target.value;
    setSelectedFilters(tempFilters);
  }

  const onClearClick = () => {
    setSelectedFilters({
      alliance: undefined,
      quality_rating: undefined,
      tier: undefined,
      date: undefined,
    });
    setStartDateSelected(undefined)
    setEndDateSelected(undefined)
    props.onReset();
  }

  const handleChange = (value, key) => {
    const tempFilters = { ...selectedFilters };
    tempFilters[key] = value;
    setSelectedFilters(tempFilters);
  }

  const onTierChange = (newValue) => {
    const tempFilters = { ...selectedFilters };
    tempFilters.tier = newValue;
    setSelectedFilters(tempFilters);
  }

  const onStatusChange = (newValue) => {
    const tempFilters = { ...selectedFilters };
    tempFilters.valid = newValue;
    setSelectedFilters(tempFilters);
  }

  const handleDateChange = (value, type) => {
    let start_date = 0
    let endDate = 0
    if (type === 'start_date') {
      if (value === null) {
        setStartDateSelected(undefined)
        setDateSelected({ ...dateSelected, startDate: '' })
      } else {
        setStartDateSelected(value)
        start_date = new Date(value._d).getTime() / 1000
        setDateSelected({ ...dateSelected, startDate: Math.floor(start_date) })
      }
    } else if (type === 'end_date') {
      if (value === null) {
        setEndDateSelected(undefined)
        setDateSelected({ ...dateSelected, endDate: '' })
      } else {
        setEndDateSelected(value)
        endDate = new Date(value._d).getTime() / 1000
        setDateSelected({ ...dateSelected, endDate: Math.floor(endDate) })
      }
    }
    const tempFilters = { ...selectedFilters };
    tempFilters.date = [type === 'start_date' ? Math.floor(start_date) : dateSelected.startDate === '' ? 1 : dateSelected.startDate, type === 'end_date' ? Math.floor(endDate) : dateSelected.endDate === '' ? Math.floor(Date.now() / 1000) : dateSelected.endDate];
    setSelectedFilters(tempFilters);
  }
  return (
    <div className="filter-searchresults-inside">
      <Loader loading={Object.keys(searchResultFilterForTabResponse.data).length !== apiBasedFilters[props.dataset].length || searchResultFilterForTabResponse.loading} error={searchResultFilterForTabResponse.error}>
        {searchResultFilterForTabResponse.flag && Object.keys(searchResultFilterForTabResponse.data).length === apiBasedFilters[props.dataset].length
          ? (
            <div className="searchresults-filter-bar">
              <div className="searchresults-filter-section">
                {props.dataset !== 'clinical_trials'
                  ? (
                    <div className="searchresults-filter-section-filter">
                      <div className="searchresults-filter-section-filter-radio">
                        <div className="filter-value-title">Alliance</div>
                        <div className="radio-btn-multiple">
                          <Radio.Group value={selectedFilters.alliance} onChange={(e) => onRadioFilterChange(e, 'alliance')}>
                            <Radio value="Yes">Yes</Radio>
                            <Radio value="No">No</Radio>
                          </Radio.Group>
                        </div>
                      </div>
                    </div>
                  )
                  : null}
                {searchResultFilterForTabResponse?.data?.alliance_type
                  ? (
                    <div className="searchresults-filter-section-filter">
                      <div className="searchresults-filter-section-filter-select">
                        <div className="filter-value-title">Alliance type</div>
                        <Select
                          mode="multiple"
                          allowClear
                          placeholder="Search alliance types"
                          onChange={(val) => handleChange(val, 'alliance_type')}
                          maxTagCount={1}
                          value={selectedFilters.alliance_type || []}
                        >
                          {searchResultFilterForTabResponse?.data?.alliance_type?.map((filterItm, index) => (
                            <Option key={index} value={filterItm.key}>
                              {filterItm.category}
                            </Option>
                          ))}
                        </Select>
                      </div>
                    </div>
                  )
                  : null}
                {searchResultFilterForTabResponse?.data?.department
                  ? (
                    <div className="searchresults-filter-section-filter">
                      <div className="searchresults-filter-section-filter-select">
                        <div className="filter-value-title">Department</div>
                        <Select
                          mode="multiple"
                          allowClear
                          placeholder="Search department"
                          onChange={(val) => handleChange(val, 'department')}
                          maxTagCount={1}
                          value={selectedFilters.department || []}
                        >
                          {searchResultFilterForTabResponse?.data?.department?.map((filterItm, index) => (
                            <Option key={index} value={filterItm.key}>
                              {filterItm.category}
                            </Option>
                          ))}
                        </Select>
                      </div>
                    </div>
                  )
                  : null}
                {searchResultFilterForTabResponse?.data?.indications
                  ? (
                    <div className="searchresults-filter-section-filter">
                      <div className="searchresults-filter-section-filter-select">
                        <div className="filter-value-title">Indications</div>
                        <Select
                          mode="multiple"
                          allowClear
                          placeholder="Search indications"
                          onChange={(val) => handleChange(val, 'indications')}
                          maxTagCount={1}
                          value={selectedFilters.indications || []}
                        >
                          {searchResultFilterForTabResponse?.data?.indications?.map((filterItm, index) => (
                            <Option key={index} value={filterItm.key}>
                              {filterItm.category}
                            </Option>
                          ))}
                        </Select>
                      </div>
                    </div>
                  )
                  : null}
                {searchResultFilterForTabResponse?.data?.countries
                  ? (
                    <div className="searchresults-filter-section-filter">
                      <div className="searchresults-filter-section-filter-select">
                        <div className="filter-value-title">Country</div>
                        <Select
                          mode="multiple"
                          allowClear
                          placeholder="Search countries"
                          onChange={(val) => handleChange(val, 'countries')}
                          maxTagCount={1}
                          value={selectedFilters.countries || []}
                        >
                          {searchResultFilterForTabResponse?.data?.countries?.map((filterItm, index) => (
                            <Option key={index} value={filterItm.key}>
                              {filterItm.category}
                            </Option>
                          ))}
                        </Select>
                      </div>
                    </div>
                  )
                  : null}
                {searchResultFilterForTabResponse?.data?.sources
                  ? (
                    <div className="searchresults-filter-section-filter">
                      <div className="searchresults-filter-section-filter-select">
                        <div className="filter-value-title">Sources</div>
                        <Select
                          mode="multiple"
                          allowClear
                          placeholder="Search sources"
                          onChange={(val) => handleChange(val, 'sources')}
                          maxTagCount={1}
                          value={selectedFilters.sources || []}
                        >
                          {searchResultFilterForTabResponse?.data?.sources?.map((filterItm, index) => (
                            <Option key={index} value={filterItm.key}>
                              {filterItm.category}
                            </Option>
                          ))}
                        </Select>
                      </div>
                    </div>
                  )
                  : null}
                {searchResultFilterForTabResponse?.data?.compliance
                  ? (
                    <div className="searchresults-filter-section-filter">
                      <div className="searchresults-filter-section-filter-select">
                        <div className="filter-value-title">Compliance issue</div>
                        <Select
                          mode="multiple"
                          allowClear
                          placeholder="Search compliance issues"
                          onChange={(val) => handleChange(val, 'compliance')}
                          maxTagCount={1}
                          value={selectedFilters.compliance || []}
                        >
                          {searchResultFilterForTabResponse?.data?.compliance?.map((filterItm, index) => (
                            <Option key={index} value={filterItm.key}>
                              {filterItm.category}
                            </Option>
                          ))}
                        </Select>
                      </div>
                    </div>
                  )
                  : null}
                {searchResultFilterForTabResponse?.data?.primary_center_types
                  ? (
                    <div className="searchresults-filter-section-filter">
                      <div className="searchresults-filter-section-filter-select">
                        <div className="filter-value-title">Organization type</div>
                        <Select
                          mode="multiple"
                          allowClear
                          placeholder="Search Organization Type"
                          onChange={(val) => handleChange(val, 'primary_center_types')}
                          maxTagCount={1}
                          value={selectedFilters.primary_center_types || []}
                        >
                          {searchResultFilterForTabResponse?.data?.primary_center_types?.map((filterItm, index) => (
                            <Option key={index} value={filterItm.key}>
                              {filterItm.category}
                            </Option>
                          ))}
                        </Select>
                      </div>
                    </div>
                  )
                  : null}
                {searchResultFilterForTabResponse?.data?.center_type
                  ? (
                    <div className="searchresults-filter-section-filter">
                      <div className="searchresults-filter-section-filter-select">
                        <div className="filter-value-title">Organization type</div>
                        <Select
                          mode="multiple"
                          allowClear
                          placeholder="Search Organization Type"
                          onChange={(val) => handleChange(val, 'center_type')}
                          maxTagCount={1}
                          value={selectedFilters.center_type || []}
                        >
                          {searchResultFilterForTabResponse?.data?.center_type?.map((filterItm, index) => (
                            <Option key={index} value={filterItm.key}>
                              {filterItm.category}
                            </Option>
                          ))}
                        </Select>
                      </div>
                    </div>
                  )
                  : null}
                {searchResultFilterForTabResponse?.data?.status
                  ? (
                    <div className="searchresults-filter-section-filter">
                      <div className="searchresults-filter-section-filter-select">
                        <div className="filter-value-title">Status</div>
                        <Select
                          mode="multiple"
                          allowClear
                          placeholder="Search Status"
                          onChange={(val) => handleChange(val, 'status')}
                          maxTagCount={1}
                          value={selectedFilters.status || []}
                        >
                          {searchResultFilterForTabResponse?.data?.status?.map((filterItm, index) => (
                            <Option key={index} value={filterItm.key}>
                              {filterItm.category}
                            </Option>
                          ))}
                        </Select>
                      </div>
                    </div>
                  )
                  : null}
                {searchResultFilterForTabResponse?.data?.phase
                  ? (
                    <div className="searchresults-filter-section-filter">
                      <div className="searchresults-filter-section-filter-select">
                        <div className="filter-value-title">Phase</div>
                        <Select
                          mode="multiple"
                          allowClear
                          placeholder="Search Phase"
                          onChange={(val) => handleChange(val, 'phase')}
                          maxTagCount={1}
                          value={selectedFilters.phase || []}
                        >
                          {searchResultFilterForTabResponse?.data?.phase?.map((filterItm, index) => (
                            <Option key={index} value={filterItm.key}>
                              {filterItm.category}
                            </Option>
                          ))}
                        </Select>
                      </div>
                    </div>
                  )
                  : null}
                {searchResultFilterForTabResponse?.data?.sponsor
                  ? (
                    <div className="searchresults-filter-section-filter">
                      <div className="searchresults-filter-section-filter-select">
                        <div className="filter-value-title">Sponsor</div>
                        <Select
                          mode="multiple"
                          allowClear
                          placeholder="Search Sponsor"
                          onChange={(val) => handleChange(val, 'sponsor')}
                          maxTagCount={1}
                          value={selectedFilters.sponsor || []}
                        >
                          {searchResultFilterForTabResponse?.data?.sponsor?.map((filterItm, index) => (
                            <Option key={index} value={filterItm.key}>
                              {filterItm.category}
                            </Option>
                          ))}
                        </Select>
                      </div>
                    </div>
                  )
                  : null}

                {props.dataset !== 'clinical_trials' && props.dataset !== 'parent_centers'
                  ? (
                    <>
                      <div className="searchresults-filter-section-combine  searchresults-filter-section-combine-rating">

                        <div className="searchresults-filter-section-filter">
                          <div className="searchresults-filter-section-filter-select">
                            <div className="rang-filter-wrap">
                              <div className="rang-filter-wrap-title">
                                <div className="filter-value-title">Tier</div>
                              </div>
                              <div className="range-filter-wrap range-filter-wrap-combine">
                                <Select mode="multiple" placeholder="Search Tier" value={selectedFilters.tier ? selectedFilters.tier : []} onChange={onTierChange}>
                                  <Option value={0}>0</Option>
                                  <Option value={1}>1</Option>
                                  <Option value={2}>2</Option>
                                  <Option value={3}>3</Option>
                                </Select>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  )
                  : null}
                {props.dataset !== 'clinical_trials'
                  ? (
                    <>
                      <div className="searchresults-filter-section-combine  searchresults-filter-section-combine-rating">

                        <div className="searchresults-filter-section-filter">
                          <div className="searchresults-filter-section-filter-select">
                            <div className="rang-filter-wrap">
                              <div className="rang-filter-wrap-title">
                                <div className="filter-value-title">Status</div>
                              </div>
                              <div className="range-filter-wrap range-filter-wrap-combine">
                                <Select value={selectedFilters.valid === 'No' ? 'Invalid' : selectedFilters.valid === 'Yes' ? 'Valid' : []} onChange={(val) => onStatusChange(val)}>
                                  <Option value="Yes">Valid</Option>
                                  <Option value="No">Invalid</Option>
                                </Select>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  )
                  : null}
                {props.dataset === 'clinical_trials'
                  ? (
                    <>
                      <div className="searchresults-filter-section-filter">
                        <div className="searchresults-filter-section-filter-select">
                          <div className="filter-value-title">Start date</div>
                          <DatePicker
                            onChange={(e) => handleDateChange(e, 'start_date')}
                            value={startDateSelected}
                            format="DD-MMM-YYYY"

                          />
                        </div>
                      </div>
                      <div className="searchresults-filter-section-filter">
                        <div className="searchresults-filter-section-filter-select">
                          <div className="filter-value-title">End date</div>
                          <DatePicker
                            onChange={(e) => handleDateChange(e, 'end_date')}
                            value={endDateSelected}
                            format="DD-MMM-YYYY"

                          />
                        </div>
                      </div>
                    </>
                  )
                  : null}
                {props.dataset === 'investigators'
                  ? (
                    <div className="searchresults-filter-section-filter">
                      <div className="searchresults-filter-section-filter-select">
                        <div className="filter-value-title">KOL Score Class</div>
                        <Select
                          mode="multiple"
                          allowClear
                          placeholder="select the kol score class"
                          onChange={(val) => handleChange(val, 'cls_kol_score_class')}
                          value={selectedFilters.cls_kol_score_class || []}
                        >
                          <Option value="High"> High </Option>
                          <Option value="Medium"> Moderate </Option>
                          <Option value="Low"> Low </Option>
                        </Select>
                      </div>
                    </div>
                  )
                  : null}
                {props.dataset === 'investigators'
                  ? (
                    <div className="searchresults-filter-section-filter">
                      <div className="searchresults-filter-section-filter-select">
                        <div className="filter-value-title">Emerging KOL Class</div>
                        <Select
                          mode="multiple"
                          allowClear
                          placeholder="select the emerging kol class"
                          onChange={(val) => handleChange(val, 'emerging_tag')}
                          // maxTagCount={1}
                          value={selectedFilters.emerging_tag || []}
                        >
                          <Option value="High"> Rising </Option>
                          <Option value="Medium"> Stable </Option>
                          <Option value="Low"> Falling </Option>
                          <Option value="Not Applicable">Not Applicable</Option>
                        </Select>
                      </div>
                    </div>
                  )
                  : null}
              </div>
              <div className="filter-submit-btns">
                <Button className="clear-btn" onClick={() => onClearClick()}>Clear</Button>
                <Button className="apply-btn" type="primary" onClick={() => props.onApplyFilters(selectedFilters)}>Apply</Button>
              </div>
            </div>
          )
          : null}
      </Loader>
    </div>
  )
}

export default SearchResultFilters;
