import React from 'react'
import {
  Form, Radio, Space, Input, Button, DatePicker,
} from 'antd'
import { useSelector } from 'react-redux';
import moment from 'moment';
import { getCookie } from '../../../utils';

export default function StartUpGeneralAdmin({ data }) {
  const siteQuesResponse = useSelector((store) => store.siteQuesResponse);

  const getValue = (keyName) => {
    return data?.startUpGeneral[keyName] || data.startUpGeneral.profile_responses_new[keyName];
  }

  const onCenterStateChange = (e, keyName) => {
    const { value } = e.target;
    data.setStartUpGeneral((oldState) => {
      const temp = { ...oldState };
      temp.profile_responses_new[keyName] = value;
      return { ...oldState, ...temp };
    });
  };

  const onDateChange = (date, dateString, keyName) => {
    data.setStartUpGeneral((oldState) => {
      const temp = {};
      temp[keyName] = dateString;
      return { ...oldState, ...temp };
    });
  };

  const showLabelOptions = (itm) => {
    const { child_queston } = itm
    if (child_queston.length) {
      return child_queston?.map((child, idx) => {
        const itemChild = Object.keys(siteQuesResponse?.data?.data)
          .map((res) => siteQuesResponse.data.data[res]).find((o) => o.id === child)
        return renderQuesAns(itemChild, idx)
      })
    }
    return ''
  }

  const showOptions = (itm) => {
    const { options } = itm
    if (options.length) {
      return options.map((opt) => {
        if (getValue(itm.id) === opt.option_text) {
          const childQue = opt.child_questions
          return childQue?.map((child, idx) => {
            const itemChild = Object.keys(siteQuesResponse?.data?.data)
              .map((res) => siteQuesResponse.data.data[res]).find((o) => o.id === child)
            return renderQuesAns(itemChild, idx)
          })
        }
        return ''
      })
    }
    return ''
  }

  const showOldValues = (id) => {
    if (data.flag === 'edit-admin') {
      if (data.startUpGeneral.profile_responses_old.flag === true) {
        return data.startUpGeneral.profile_responses_old[id]
      }
      return data.startUpGeneral.profile_responses_old[id]
    }
    return null
  }

  const convertDate = () => {
    if (getValue('last_onsite_visit_date')) {
      const visitDate = moment(getValue('last_onsite_visit_date')).format('YYYY-MM-DD')
      return moment(visitDate, 'YYYY-MM-DD')
    }
    return undefined
  }

  const dateFormat = 'YYYY-MM-DD'
  const userId = getCookie('userName')

  const renderQuesAns = (itm, idx) => {
    return (
      <Form layout="vertical" className={(userId === data.checkUserId && data.checkStatus === 'Send_for_clarification') ? null : 'disable-pi-form'}>
        <div className="head-wrapper">
          <div className="inner-card">

            <div className={(data.flag === 'edit-admin' && showOldValues(itm.id)) || (data.flag === 'edit-admin' && showOldValues(itm.id) === '') ? 'question-card highlight-input-border' : 'question-card'}>
              <div>{idx + 1 }.</div>
              <Form.Item
                label={itm.question_text}
                className="question-wrapper"
              >
                {itm.type === 'single_select' ? (
                  <>
                    <Radio.Group onChange={(e) => onCenterStateChange(e, itm.id)} value={getValue(itm.id)}>
                      <Space direction="vertical">
                        {itm.options.map((j) => {
                          return <Radio value={j.option_text}>{j.option_text}</Radio>
                        })}
                      </Space>
                    </Radio.Group>
                    {showOptions(itm)}
                  </>
                ) : itm.type === 'text' ? (
                  <Input
                    className="input-wrapper"
                    placeholder="Please enter"
                    value={getValue(itm.id)}
                    onChange={(e) => onCenterStateChange(e, itm.id)}
                  />
                ) : itm.type === 'file_upload' ? (
                  <Button type="primary" disabled>Upload here</Button>
                ) : itm.type === 'date' ? (
                  <Space direction="vertical">
                    <DatePicker
                      onChange={(date, dateString) => onDateChange(date, dateString, itm.id)}
                      value={convertDate()}
                      allowClear={false}
                      format={dateFormat}
                      placeholder={dateFormat}
                    />
                  </Space>
                ) : itm.type === 'label' ? showLabelOptions(itm) : ''}
                <div className="input-old-value">
                  {data.flag === 'edit-admin' && showOldValues(itm.id)
                    ? `Answer changed from ${showOldValues(itm.id)}`
                    : data.flag === 'edit-admin' && showOldValues(itm.id) === '' ? 'New answer is added'
                      : null}
                </div>
              </Form.Item>
            </div>
          </div>
        </div>
      </Form>
    )
  }

  return (
    <div>
      <div className="title-input-sec">
        Start-up - General
      </div>
      <div className={(userId === data.checkUserId && data.checkStatus === 'Send_for_clarification') ? null : 'disable-pi'}>
        {siteQuesResponse && siteQuesResponse?.data?.data?.filter((itm) => (itm.child_category
        === 'Start-up - General' && itm.parent_id === 0)).map((itm, idx) => {
          const childQues = itm.child_queston
          if (childQues.length) {
            childQues.map((child) => {
              return renderQuesAns(siteQuesResponse?.data?.data?.find((i) => i.id === child), idx)
            })
          }
          return renderQuesAns(itm, idx)
        })}
      </div>
    </div>
  )
}
