import { stringify } from 'query-string'
import { GENERIC_ACTION } from '../SearchResults/logic'

const SHARE_ANALYSIS = 'SHARE_ANALYSIS'
const SHARE_ANALYSIS_SUCCESS = 'SHARE_ANALYSIS_SUCCESS'
const SHARE_ANALYSIS_RESET = 'SHARE_ANALYSIS_RESET'
const SHARE_ANALYSIS_FAILURE = 'SHARE_ANALYSIS_FAILURE'

const CHANGE_SHARE_ANALYSIS_ACCESS = 'CHANGE_SHARE_ANALYSIS_ACCESS'
const CHANGE_SHARE_ANALYSIS_ACCESS_SUCCESS = 'CHANGE_SHARE_ANALYSIS_ACCESS_SUCCESS'
const CHANGE_SHARE_ANALYSIS_ACCESS_RESET = 'CHANGE_SHARE_ANALYSIS_ACCESS_RESET'
const CHANGE_SHARE_ANALYSIS_ACCESS_FAILURE = 'CHANGE_SHARE_ANALYSIS_ACCESS_FAILURE'

const GET_SHARED_ANALYSIS_DATA = 'GET_SHARED_ANALYSIS_DATA'
const GET_SHARED_ANALYSIS_DATA_SUCCESS = 'GET_SHARED_ANALYSIS_DATA_SUCCESS'
const GET_SHARED_ANALYSIS_DATA_RESET = 'GET_SHARED_ANALYSIS_DATA_RESET'
const GET_SHARED_ANALYSIS_DATA_FAILURE = 'GET_SHARED_ANALYSIS_DATA_FAILURE'

const INITIAL_STATE = {
  data: {},
  loading: false,
  error: false,
  flag: false,
}

// share analysis
export const shareAnalysisAction = (payload, meta = {}) => ({
  type: GENERIC_ACTION,
  payload: payload.body,
  meta: {
    actionType: SHARE_ANALYSIS,
    url: `${process.env.apiUrl}v0/analyses/shareAnalysis?${stringify(payload.params)}`,
    method: 'POST',
    auth: true,
    log: false, // for console
    ...meta,
  },
  showNotification: true, // show notification on success
  message: 'Analysis shared successfully',
  hideNotification: false, // hide notification on error
})

export const shareAnalysisResetAction = () => ({
  type: SHARE_ANALYSIS_RESET,
})

export const updateAnalysisAccessAction = (payload, meta = {}) => ({
  type: GENERIC_ACTION,
  payload: payload.body,
  meta: {
    actionType: CHANGE_SHARE_ANALYSIS_ACCESS,
    url: `${process.env.apiUrl}v0/analyses/shareAnalysis?${stringify(payload.params)}`,
    method: 'PUT',
    auth: true,
    log: false, // for console
    ...meta,
  },
  showNotification: true, // show notification on success
  message: meta.isRemoveReq ? 'Analysis access removed successfully' : 'Analysis access updated successfully',
  hideNotification: false, // hide notification on error
})

export const updateAnalysisAccessResetAction = () => ({
  type: CHANGE_SHARE_ANALYSIS_ACCESS_RESET,
})

export const getSharedAnalysisUsersDataAction = (payload, meta = {}) => ({
  type: GENERIC_ACTION,
  payload,
  meta: {
    actionType: GET_SHARED_ANALYSIS_DATA,
    url: `${process.env.apiUrl}v0/analyses/sharedAnalysisUsers`,
    method: 'GET',
    auth: true,
    log: false, // for console
    ...meta,
  },
  showNotification: false, // show notification on success
  hideNotification: false, // hide notification on error
})

export const getSharedAnalysisUsersDataResetAction = () => ({
  type: GET_SHARED_ANALYSIS_DATA_RESET,
})

export function shareAnalysisReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case SHARE_ANALYSIS: {
      return {
        data: {},
        loading: true,
        flag: false,
        error: false,
      }
    }
    case SHARE_ANALYSIS_SUCCESS: {
      return {
        data: action.payload,
        loading: false,
        flag: true,
        error: false,
      }
    }
    case SHARE_ANALYSIS_RESET: {
      return {
        data: {},
        loading: false,
        flag: false,
        error: false,
      }
    }
    case SHARE_ANALYSIS_FAILURE: {
      return {
        data: {},
        loading: false,
        flag: false,
        error: true,
      }
    }
    default:
      return state
  }
}

export function changeShareAnalysisAccessReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case CHANGE_SHARE_ANALYSIS_ACCESS: {
      return {
        data: {},
        loading: true,
        flag: false,
        error: false,
      }
    }
    case CHANGE_SHARE_ANALYSIS_ACCESS_SUCCESS: {
      return {
        data: action.payload,
        loading: false,
        flag: true,
        error: false,
      }
    }
    case CHANGE_SHARE_ANALYSIS_ACCESS_RESET: {
      return {
        data: {},
        loading: false,
        flag: false,
        error: false,
      }
    }
    case CHANGE_SHARE_ANALYSIS_ACCESS_FAILURE: {
      return {
        data: {},
        loading: false,
        flag: false,
        error: true,
      }
    }
    default:
      return state
  }
}

export function getSharedAnalysisUsersDataReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case GET_SHARED_ANALYSIS_DATA: {
      return {
        data: {},
        loading: true,
        flag: false,
        error: false,
      }
    }
    case GET_SHARED_ANALYSIS_DATA_SUCCESS: {
      return {
        data: action.payload,
        loading: false,
        flag: true,
        error: false,
      }
    }
    case GET_SHARED_ANALYSIS_DATA_RESET: {
      return {
        data: {},
        loading: false,
        flag: false,
        error: false,
      }
    }
    case GET_SHARED_ANALYSIS_DATA_FAILURE: {
      return {
        data: {},
        loading: false,
        flag: false,
        error: true,
      }
    }
    default:
      return state
  }
}
