import { setCookie } from '../../../utils';
import { GENERIC_ACTION } from '../SearchResults/logic'

const GET_PROJECT_COUNTS = 'GET_PROJECT_COUNTS'
const GET_PROJECT_COUNTS_SUCCESS = 'GET_PROJECT_COUNTS_SUCCESS'
const GET_PROJECT_COUNTS_FAILURE = 'GET_PROJECT_COUNTS_FAILURE'

const GET_NOTIFICATIONS = 'GET_NOTIFICATIONS';
const GET_NOTIFICATIONS_MORE = 'GET_NOTIFICATIONS_MORE';
const GET_NOTIFICATIONS_SUCCESS = 'GET_NOTIFICATIONS_SUCCESS';
const GET_NOTIFICATIONS_MORE_SUCCESS = 'GET_NOTIFICATIONS_MORE_SUCCESS';
const GET_NOTIFICATIONS_FAILURE = 'GET_NOTIFICATIONS_FAILURE';

const UPDATE_NOTIFICATION = 'UPDATE_NOTIFICATION';
const UPDATE_NOTIFICATION_SUCCESS = 'UPDATE_NOTIFICATION_SUCCESS';
const UPDATE_NOTIFICATION_RESET = 'UPDATE_NOTIFICATION_RESET';
const UPDATE_NOTIFICATION_FAILURE = 'UPDATE_NOTIFICATION_FAILURE';

const INITIAL_STATE = {
  data: {},
  loading: false,
  error: false,
  flag: false,
}

export const getProjectCountsAction = (payload, meta = {}) => ({
  type: GENERIC_ACTION,
  payload,
  meta: {
    actionType: GET_PROJECT_COUNTS,
    url: `${process.env.apiUrl}/v0/projects/getCount`,
    method: 'get',
    auth: true,
    // customHeaders: { 'Content-Type': undefined },
    log: false, // for console
    ...meta,
  },
  // dummyResponse: data, //dummy data in epic
  showNotification: false, // show notification on success
  hideNotification: false, // hide notification on error
})

export const getNotificationsAction = (payload, meta = {}) => ({
  type: GENERIC_ACTION,
  payload,
  meta: {
    actionType: GET_NOTIFICATIONS,
    url: `${process.env.apiUrl}/v0/Notification/getNotification`,
    method: 'get',
    auth: true,
    // customHeaders: { 'Content-Type': undefined },
    log: false, // for console
    ...meta,
  },
  // dummyResponse: data, //dummy data in epic
  showNotification: false, // show notification on success
  hideNotification: false, // hide notification on error
})

export const getNotificationsMoreAction = (payload, meta = {}) => ({
  type: GENERIC_ACTION,
  payload,
  meta: {
    actionType: GET_NOTIFICATIONS_MORE,
    url: `${process.env.apiUrl}/v0/Notification/getNotification`,
    method: 'get',
    auth: true,
    // customHeaders: { 'Content-Type': undefined },
    log: false, // for console
    ...meta,
  },
  // dummyResponse: data, //dummy data in epic
  showNotification: false, // show notification on success
  hideNotification: false, // hide notification on error
})

export const updateNotificationAction = (payload, meta = {}) => ({
  type: GENERIC_ACTION,
  payload,
  meta: {
    actionType: UPDATE_NOTIFICATION,
    url: `${process.env.apiUrl}/v0/Notification/updateNotification`,
    method: 'PUT',
    auth: true,
    // customHeaders: { 'Content-Type': undefined },
    log: false, // for console
    ...meta,
  },
  // dummyResponse: data, //dummy data in epic
  showNotification: false, // show notification on success
  hideNotification: false, // hide notification on error
})

export const updateNotificationResetAction = () => ({
  type: UPDATE_NOTIFICATION_RESET,
})

export function getProjectCountsReducer(state, action) {
  if (typeof state === 'undefined') {
    return INITIAL_STATE
  }
  switch (action.type) {
    case GET_PROJECT_COUNTS: {
      return {
        data: {},
        loading: true,
        flag: false,
        error: false,
      }
    }
    case GET_PROJECT_COUNTS_SUCCESS: {
      return {
        data: action.payload,
        loading: false,
        flag: true,
        error: false,
      }
    }
    case GET_PROJECT_COUNTS_FAILURE: {
      return {
        data: {},
        loading: false,
        flag: false,
        error: true,
      }
    }
    default:
      return state
  }
}

export function getNotificationsReducer(state, action) {
  if (typeof state === 'undefined') {
    return INITIAL_STATE
  }
  switch (action.type) {
    case GET_NOTIFICATIONS: {
      return {
        data: {},
        loading: true,
        flag: false,
        error: false,
      }
    }
    case GET_NOTIFICATIONS_SUCCESS: {
      return {
        data: action.payload,
        loading: false,
        flag: true,
        error: false,
      }
    }
    case GET_NOTIFICATIONS_MORE_SUCCESS: {
      return {
        data: {
          data: {
            ...state.data.data,
            all_notifications_list: [
              ...state.data.data.all_notifications_list,
              ...action.payload.data.all_notifications_list,
            ],
          },
        },
        loading: false,
        flag: true,
        error: false,
      }
    }
    case GET_NOTIFICATIONS_FAILURE: {
      return {
        data: {},
        loading: false,
        flag: false,
        error: true,
      }
    }
    default:
      return state
  }
}

export function updateNotificationReducer(state, action) {
  if (typeof state === 'undefined') {
    return INITIAL_STATE
  }
  switch (action.type) {
    case UPDATE_NOTIFICATION: {
      return {
        data: {},
        loading: true,
        flag: false,
        error: false,
      }
    }
    case UPDATE_NOTIFICATION_SUCCESS: {
      return {
        data: action.payload,
        loading: false,
        flag: true,
        error: false,
      }
    }
    case UPDATE_NOTIFICATION_RESET: {
      return {
        data: {},
        loading: false,
        flag: false,
        error: false,
      }
    }
    case UPDATE_NOTIFICATION_FAILURE: {
      return {
        data: {},
        loading: false,
        flag: false,
        error: true,
      }
    }
    default:
      return state
  }
}

const GET_TAGGED_COMMENTS = 'GET_TAGGED_COMMENTS';
const GET_TAGGED_COMMENTS_MORE = 'GET_TAGGED_COMMENTS_MORE';
const GET_TAGGED_COMMENTS_SUCCESS = 'GET_TAGGED_COMMENTS_SUCCESS';
const GET_TAGGED_COMMENTS_MORE_SUCCESS = 'GET_TAGGED_COMMENTS_MORE_SUCCESS';
const GET_TAGGED_COMMENTS_FAILURE = 'GET_TAGGED_COMMENTS_FAILURE';

export function getTaggedCommentsReducer(state, action) {
  if (typeof state === 'undefined') {
    return INITIAL_STATE
  }
  switch (action.type) {
    case GET_TAGGED_COMMENTS: {
      return {
        data: {},
        loading: true,
        flag: false,
        error: false,
      }
    }
    case GET_TAGGED_COMMENTS_SUCCESS: {
      return {
        data: action.payload,
        loading: false,
        flag: true,
        error: false,
      }
    }
    case GET_TAGGED_COMMENTS_MORE_SUCCESS: {
      return {
        data: {
          data: [
            ...state.data.data,
            ...action.payload.data,
          ],
          total: action.payload.total,
        },
        loading: false,
        flag: true,
        error: false,
      }
    }
    case GET_TAGGED_COMMENTS_FAILURE: {
      return {
        data: {},
        loading: false,
        flag: false,
        error: true,
      }
    }
    default:
      return state
  }
}

export const getTaggedCommentsAction = (payload, meta = {}) => ({
  type: GENERIC_ACTION,
  payload,
  meta: {
    actionType: GET_TAGGED_COMMENTS,
    url: `${process.env.apiUrl}/v0/comment/userTaggedComments`,
    method: 'get',
    auth: true,
    // customHeaders: { 'Content-Type': undefined },
    log: false, // for console
    ...meta,
  },
  // dummyResponse: data, //dummy data in epic
  showNotification: false, // show notification on success
  hideNotification: false, // hide notification on error
})

export const getTaggedCommentsMoreAction = (payload, meta = {}) => ({
  type: GENERIC_ACTION,
  payload,
  meta: {
    actionType: GET_TAGGED_COMMENTS_MORE,
    url: `${process.env.apiUrl}/v0/comment/userTaggedComments`,
    method: 'get',
    auth: true,
    // customHeaders: { 'Content-Type': undefined },
    log: false, // for console
    ...meta,
  },
  // dummyResponse: data, //dummy data in epic
  showNotification: false, // show notification on success
  hideNotification: false, // hide notification on error
})

const GET_ACCESS_PERMISSIONS = 'GET_ACCESS_PERMISSIONS'
const GET_ACCESS_PERMISSIONS_SUCCESS = 'GET_ACCESS_PERMISSIONS_SUCCESS'
const GET_ACCESS_PERMISSIONS_FAILURE = 'GET_ACCESS_PERMISSIONS_FAILURE'

export const getAccessPermissionsAction = (payload, meta = {}) => ({
  type: GENERIC_ACTION,
  payload,
  meta: {
    actionType: GET_ACCESS_PERMISSIONS,
    url: `${process.env.apiUrl}/v0/roles_dashboard/getRoles`,
    method: 'get',
    auth: true,
    // customHeaders: { 'Content-Type': undefined },
    log: false, // for console
    ...meta,
  },
  // dummyResponse: data, //dummy data in epic
  showNotification: false, // show notification on success
  hideNotification: false, // hide notification on error
})

export function getAccessPermissionsReducer(state, action) {
  if (typeof state === 'undefined') {
    return INITIAL_STATE
  }
  switch (action.type) {
    case GET_ACCESS_PERMISSIONS: {
      return {
        data: {},
        loading: true,
        flag: false,
        error: false,
      }
    }
    case GET_ACCESS_PERMISSIONS_SUCCESS: {
      if (action?.payload?.permissions) {
        setCookie(JSON.stringify(action.payload.permissions), 'access_permissions')
      }
      return {
        data: action.payload,
        loading: false,
        flag: true,
        error: false,
      }
    }

    case GET_ACCESS_PERMISSIONS_FAILURE: {
      return {
        data: {},
        loading: false,
        flag: false,
        error: true,
      }
    }
    default:
      return state
  }
}
