import { GENERIC_ACTION } from '../SearchResults/logic'

const GET_STARTUP_DATA = 'GET_STARTUP_DATA'
const GET_STARTUP_DATA_SUCCESS = 'GET_STARTUP_DATA_SUCCESS'
const GET_STARTUP_DATA_FAILURE = 'GET_STARTUP_DATA_FAILURE'

const INITIAL_STATE = {
  data: {},
  loading: false,
  error: false,
  flag: false,
}
export const getStartUpAction = (payload, meta = {}) => ({
  type: GENERIC_ACTION,
  payload,
  meta: {
    actionType: GET_STARTUP_DATA,
    url: `${process.env.apiUrl}v0/search/startUp`,
    method: 'GET',
    auth: true,
    // customHeaders: { 'Content-Type': undefined },
    log: false, // for console
    ...meta,
  },
  showNotification: false, // show notification on success
  hideNotification: false, // hide notification on error
})

export function getStartUpReducer(state, action) {
  if (typeof state === 'undefined') {
    return INITIAL_STATE
  }
  switch (action.type) {
    case GET_STARTUP_DATA: {
      return {
        data: {},
        loading: true,
        flag: false,
        error: false,
      }
    }
    case GET_STARTUP_DATA_SUCCESS: {
      if (action.meta.type) {
        return {
          data: {
            ...state.data, [action.meta.type]: action.payload.data,
          },
          loading: false,
          flag: true,
          error: false,
        }
      }
      return {
        data: action.payload,
        loading: false,
        flag: true,
        error: false,
      }
    }
    case GET_STARTUP_DATA_FAILURE: {
      return {
        data: {},
        loading: false,
        flag: false,
        error: true,
      }
    }
    default:
      return state
  }
}

const GET_STARTUP_SITE_DATA = 'GET_STARTUP_SITE_DATA'
const GET_STARTUP_SITE_DATA_SUCCESS = 'GET_STARTUP_SITE_DATA_SUCCESS'
const GET_STARTUP_SITE_DATA_FAILURE = 'GET_STARTUP_SITE_DATA_FAILURE'

export const getStartUpSiteAction = (payload, meta = {}) => ({
  type: GENERIC_ACTION,
  payload,
  meta: {
    actionType: GET_STARTUP_SITE_DATA,
    url: `${process.env.apiUrl}v0/search/startUp`,
    method: 'GET',
    auth: true,
    log: false, // for console
    ...meta,
  },
  showNotification: false, // show notification on success
  hideNotification: false, // hide notification on error
})

export function getStartUpSiteReducer(state, action) {
  if (typeof state === 'undefined') {
    return INITIAL_STATE
  }
  switch (action.type) {
    case GET_STARTUP_SITE_DATA: {
      return {
        data: {},
        loading: true,
        flag: false,
        error: false,
      }
    }
    case GET_STARTUP_SITE_DATA_SUCCESS: {
      if (action.meta.type) {
        return {
          data: {
            ...state.data, [action.meta.type]: action.payload.data,
          },
          loading: false,
          flag: true,
          error: false,
        }
      }
      return {
        data: action.payload,
        loading: false,
        flag: true,
        error: false,
      }
    }
    case GET_STARTUP_SITE_DATA_FAILURE: {
      return {
        data: {},
        loading: false,
        flag: false,
        error: true,
      }
    }
    default:
      return state
  }
}
