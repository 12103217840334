import { stringify } from 'query-string'
import { GENERIC_ACTION } from '../SearchResults/logic'

const SHARE_PROJECT = 'SHARE_PROJECT'
const SHARE_PROJECT_SUCCESS = 'SHARE_PROJECT_SUCCESS'
const SHARE_PROJECT_RESET = 'SHARE_PROJECT_RESET'
const SHARE_PROJECT_FAILURE = 'SHARE_PROJECT_FAILURE'

const CHANGE_SHARE_PROJECT_ACCESS = 'CHANGE_SHARE_PROJECT_ACCESS'
const CHANGE_SHARE_PROJECT_ACCESS_SUCCESS = 'CHANGE_SHARE_PROJECT_ACCESS_SUCCESS'
const CHANGE_SHARE_PROJECT_ACCESS_RESET = 'CHANGE_SHARE_PROJECT_ACCESS_RESET'
const CHANGE_SHARE_PROJECT_ACCESS_FAILURE = 'CHANGE_SHARE_PROJECT_ACCESS_FAILURE'

const GET_SHARED_PROJECT_DATA = 'GET_SHARED_PROJECT_DATA'
const GET_SHARED_PROJECT_DATA_SUCCESS = 'GET_SHARED_PROJECT_DATA_SUCCESS'
const GET_SHARED_PROJECT_DATA_RESET = 'GET_SHARED_PROJECT_DATA_RESET'
const GET_SHARED_PROJECT_DATA_FAILURE = 'GET_SHARED_PROJECT_DATA_FAILURE'

const GET_SPONSOR_LIST = 'GET_SPONSOR_LIST'
const GET_SPONSOR_LIST_SUCCESS = 'GET_SPONSOR_LIST_SUCCESS'
const GET_SPONSOR_LIST_RESET = 'GET_SPONSOR_LIST_RESET'
const GET_SPONSOR_LIST_FAILURE = 'GET_SPONSOR_LIST_FAILURE'

const CREATE_SPONSOR = 'CREATE_SPONSOR'
const CREATE_SPONSOR_SUCCESS = 'CREATE_SPONSOR_SUCCESS'
const CREATE_SPONSOR_RESET = 'CREATE_SPONSOR_RESET'
const CREATE_SPONSOR_FAILURE = 'CREATE_SPONSOR_FAILURE'

const CREATE_SPONSOR_USER = 'CREATE_SPONSOR_USER'
const CREATE_SPONSOR_USER_SUCCESS = 'CREATE_SPONSOR_USER_SUCCESS'
const CREATE_SPONSOR_USER_RESET = 'CREATE_SPONSOR_USER_RESET'
const CREATE_SPONSOR_USER_FAILURE = 'CREATE_SPONSOR_USER_FAILURE'

const GET_SPONSOR_USER_LIST = 'GET_SPONSOR_USER_LIST'
const GET_SPONSOR_USER_LIST_SUCCESS = 'GET_SPONSOR_USER_LIST_SUCCESS'
const GET_SPONSOR_USER_LIST_RESET = 'GET_SPONSOR_USER_LIST_RESET'
const GET_SPONSOR_USER_LIST_FAILURE = 'GET_SPONSOR_USER_LIST_FAILURE'

const LIST_USERS = 'LIST_USERS'
const LIST_USERS_SUCCESS = 'LIST_USERS_SUCCESS'
const LIST_USERS_RESET = 'LIST_USERS_RESET'
const LIST_USERS_FAILURE = 'LIST_USERS_FAILURE'

const INITIAL_STATE = {
  data: {},
  loading: false,
  error: false,
  flag: false,
}

// share project
export const shareProjectAction = (payload, meta = {}) => ({
  type: GENERIC_ACTION,
  payload: payload.body,
  meta: {
    actionType: SHARE_PROJECT,
    url: `${process.env.apiUrl}v0/projects/shareProject?${stringify(payload.params)}`,
    method: 'POST',
    auth: true,
    log: false, // for console
    ...meta,
  },
  showNotification: true, // show notification on success
  message: 'Project shared successfully',
  hideNotification: false, // hide notification on error
})

export const shareProjectResetAction = () => ({
  type: SHARE_PROJECT_RESET,
})

export const CreateSponsorAction = (payload, meta = {}) => ({
  type: GENERIC_ACTION,
  payload: payload.body,
  meta: {
    actionType: CREATE_SPONSOR,
    url: `${process.env.apiUrl}/v0/projects/createSponsor?${stringify(payload.params)}`,
    method: 'POST',
    auth: true,
    log: false, // for console
    ...meta,
  },
  showNotification: true, // show notification on success
  message: 'Sponsor created successfully',
  hideNotification: false, // hide notification on error
})

export const CreateSponsorResetAction = () => ({
  type: CREATE_SPONSOR_RESET,
})

export const CreateSponsorUserAction = (payload, meta = {}) => ({
  type: GENERIC_ACTION,
  payload: payload.body,
  meta: {
    actionType: CREATE_SPONSOR_USER,
    url: `${process.env.apiUrl}/v0/projects/createSponsorUserShareProject?${stringify(payload.params)}`,
    method: 'POST',
    auth: true,
    log: false, // for console
    ...meta,
  },
  showNotification: true, // show notification on success
  message: 'Sponsor user created successfully',
  hideNotification: false, // hide notification on error
})

export const CreateSponsorUserResetAction = () => ({
  type: CREATE_SPONSOR_USER_RESET,
})

export const updateProjectAccessAction = (payload, meta = {}) => ({
  type: GENERIC_ACTION,
  payload: payload.body,
  meta: {
    actionType: CHANGE_SHARE_PROJECT_ACCESS,
    url: `${process.env.apiUrl}v0/projects/shareProject?${stringify(payload.params)}`,
    method: 'PUT',
    auth: true,
    log: false, // for console
    ...meta,
  },
  showNotification: true, // show notification on success
  message: meta.isRemoveReq ? 'Project access removed successfully' : 'Project access updated successfully',
  hideNotification: false, // hide notification on error
})

export const updateProjectAccessResetAction = () => ({
  type: CHANGE_SHARE_PROJECT_ACCESS_RESET,
})

export const getSharedProjectUsersDataAction = (payload, meta = {}) => ({
  type: GENERIC_ACTION,
  payload,
  meta: {
    actionType: GET_SHARED_PROJECT_DATA,
    url: `${process.env.apiUrl}v0/projects/sharedProjectUsers`,
    method: 'GET',
    auth: true,
    log: false, // for console
    ...meta,
  },
  showNotification: false, // show notification on success
  hideNotification: false, // hide notification on error
})

export const getSharedProjectUsersDataResetAction = () => ({
  type: GET_SHARED_PROJECT_DATA_RESET,
})

export const getSponsorListAction = (payload, meta = {}) => ({
  type: GENERIC_ACTION,
  payload,
  meta: {
    actionType: GET_SPONSOR_LIST,
    url: `${process.env.apiUrl}v0/projects/getAllSponsors`,
    method: 'GET',
    auth: true,
    log: false, // for console
    ...meta,
  },
  showNotification: false, // show notification on success
  hideNotification: false, // hide notification on error
})

export const getSponsorUserListAction = (payload, meta = {}) => ({
  type: GENERIC_ACTION,
  payload,
  meta: {
    actionType: GET_SPONSOR_USER_LIST,
    url: `${process.env.apiUrl}v0/projects/getSponsorUsers`,
    method: 'GET',
    auth: true,
    log: false, // for console
    ...meta,
  },
  showNotification: false, // show notification on success
  hideNotification: false, // hide notification on error
})

export const getSponsorUserListResetAction = () => ({
  type: GET_SPONSOR_USER_LIST_RESET,
})

export const getSponsorListResetAction = () => ({
  type: GET_SPONSOR_LIST_RESET,
})

export function shareProjectReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case SHARE_PROJECT: {
      return {
        data: {},
        loading: true,
        flag: false,
        error: false,
      }
    }
    case SHARE_PROJECT_SUCCESS: {
      return {
        data: action.payload,
        loading: false,
        flag: true,
        error: false,
      }
    }
    case SHARE_PROJECT_RESET: {
      return {
        data: {},
        loading: false,
        flag: false,
        error: false,
      }
    }
    case SHARE_PROJECT_FAILURE: {
      return {
        data: {},
        loading: false,
        flag: false,
        error: true,
      }
    }
    default:
      return state
  }
}

export function createSponsorReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case CREATE_SPONSOR: {
      return {
        data: {},
        loading: true,
        flag: false,
        error: false,
      }
    }
    case CREATE_SPONSOR_SUCCESS: {
      return {
        data: action.payload,
        loading: false,
        flag: true,
        error: false,
      }
    }
    case CREATE_SPONSOR_RESET: {
      return {
        data: {},
        loading: false,
        flag: false,
        error: false,
      }
    }
    case CREATE_SPONSOR_FAILURE: {
      return {
        data: action?.payload?.response?.data,
        loading: false,
        flag: false,
        error: true,
      }
    }
    default:
      return state
  }
}

export function createSponsorUserReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case CREATE_SPONSOR_USER: {
      return {
        data: {},
        loading: true,
        flag: false,
        error: false,
      }
    }
    case CREATE_SPONSOR_USER_SUCCESS: {
      return {
        data: action.payload,
        loading: false,
        flag: true,
        error: false,
      }
    }
    case CREATE_SPONSOR_USER_RESET: {
      return {
        data: {},
        loading: false,
        flag: false,
        error: false,
      }
    }
    case CREATE_SPONSOR_USER_FAILURE: {
      return {
        data: action?.payload?.response,
        loading: false,
        flag: false,
        error: true,
      }
    }
    default:
      return state
  }
}

export function getSponsorListReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case GET_SPONSOR_LIST: {
      return {
        data: {},
        loading: true,
        flag: false,
        error: false,
      }
    }
    case GET_SPONSOR_LIST_SUCCESS: {
      return {
        data: action.payload,
        loading: false,
        flag: true,
        error: false,
      }
    }
    case GET_SPONSOR_LIST_RESET: {
      return {
        data: {},
        loading: false,
        flag: false,
        error: false,
      }
    }
    case GET_SPONSOR_LIST_FAILURE: {
      return {
        data: {},
        loading: false,
        flag: false,
        error: true,
      }
    }
    default:
      return state
  }
}

export function getSponsorUserListReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case GET_SPONSOR_USER_LIST: {
      return {
        data: {},
        loading: true,
        flag: false,
        error: false,
      }
    }
    case GET_SPONSOR_USER_LIST_SUCCESS: {
      return {
        data: action.payload,
        loading: false,
        flag: true,
        error: false,
      }
    }
    case GET_SPONSOR_USER_LIST_RESET: {
      return {
        data: {},
        loading: false,
        flag: false,
        error: false,
      }
    }
    case GET_SPONSOR_USER_LIST_FAILURE: {
      return {
        data: {},
        loading: false,
        flag: false,
        error: true,
      }
    }
    default:
      return state
  }
}

export function changeShareProjectAccessReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case CHANGE_SHARE_PROJECT_ACCESS: {
      return {
        data: {},
        loading: true,
        flag: false,
        error: false,
      }
    }
    case CHANGE_SHARE_PROJECT_ACCESS_SUCCESS: {
      return {
        data: action.payload,
        loading: false,
        flag: true,
        error: false,
      }
    }
    case CHANGE_SHARE_PROJECT_ACCESS_RESET: {
      return {
        data: {},
        loading: false,
        flag: false,
        error: false,
      }
    }
    case CHANGE_SHARE_PROJECT_ACCESS_FAILURE: {
      return {
        data: {},
        loading: false,
        flag: false,
        error: true,
      }
    }
    default:
      return state
  }
}

export function getSharedProjectUsersDataReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case GET_SHARED_PROJECT_DATA: {
      return {
        data: {},
        loading: true,
        flag: false,
        error: false,
      }
    }
    case GET_SHARED_PROJECT_DATA_SUCCESS: {
      return {
        data: action.payload,
        loading: false,
        flag: true,
        error: false,
      }
    }
    case GET_SHARED_PROJECT_DATA_RESET: {
      return {
        data: {},
        loading: false,
        flag: false,
        error: false,
      }
    }
    case GET_SHARED_PROJECT_DATA_FAILURE: {
      return {
        data: {},
        loading: false,
        flag: false,
        error: true,
      }
    }
    default:
      return state
  }
}

export const ListUsersAction = (payload, meta = {}) => ({
  type: GENERIC_ACTION,
  payload,
  meta: {
    actionType: LIST_USERS,
    url: `${process.env.apiUrl}v0/users/getOrganizationUsers`,
    method: 'GET',
    auth: true,
    log: false, // for console
    ...meta,
  },
  showNotification: false, // show notification on success
  hideNotification: false, // hide notification on error
})

export function ListUsersReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case LIST_USERS: {
      return {
        data: {},
        loading: true,
        flag: false,
        error: false,
      }
    }
    case LIST_USERS_SUCCESS: {
      return {
        data: action.payload,
        loading: false,
        flag: true,
        error: false,
      }
    }
    case LIST_USERS_RESET: {
      return {
        data: {},
        loading: false,
        flag: false,
        error: false,
      }
    }
    case LIST_USERS_FAILURE: {
      return {
        data: {},
        loading: false,
        flag: false,
        error: true,
      }
    }
    default:
      return state
  }
}
