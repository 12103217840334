import { Modal, Tooltip } from 'antd';
import React from 'react';
import { InfoCircleOutlined } from '@ant-design/icons';

export default function ScoreModal({
  onItmClicked, setOnItmClicked, clickedPId, data, flag,
}) {
  const handleOk = () => {
    setOnItmClicked(false);
  };
  const handleCancel = () => {
    setOnItmClicked(false);
  };

  const onProfileTierValue = (name) => {
    if (name?.profile_tier) {
      if ('pi_ranking' in name?.profile_tier) {
        return clickedPId?.profile_tier?.pi_ranking.toFixed(2)
      }
      return clickedPId?.profile_tier?.site_ranking.toFixed(2)
    }
    return ''
  }
  return (
    <>
      <Modal
        title={data ? `${data.name}` : `${clickedPId?.pi_name || clickedPId?.site_name || clickedPId?.parent_name}`}
        style={{ marginTop: '60px', width: '100%' }}
        // className="comment-type-modal-wrapper competing-studies"
        visible={onItmClicked}
        onOk={handleOk}
        onCancel={handleCancel}
        destroyOnClose
      >
        {flag === 'tier' ? (
          <>
            <div className="score-tbl-th"> Tier :</div>
            <div className="score-tbl">
              <div className="score-tbl-td">
                <div className="title-td"># Industry studies &nbsp;
                  <span>
                    <Tooltip title="# Industry studies">
                      <InfoCircleOutlined />
                    </Tooltip>
                  </span>
                </div>
                <div className="desc-td">{clickedPId.industry_studies}</div>
              </div>
              <div className="score-tbl-td">
                <div className="title-td"># PXL Studies &nbsp;
                  <span>
                    <Tooltip title="# PXL Studies">
                      <InfoCircleOutlined />
                    </Tooltip>
                  </span>
                </div>
                <div className="desc-td">{clickedPId.pxl_studies}</div>
              </div>
              <div className="score-tbl-td">
                <div className="title-td">Recruitment Factor &nbsp;
                  <span>
                    <Tooltip title="Mean value of 'Site Recruitment Fctr SC Avg' for PI where 'Site_Activated'=='Yes'">
                      <InfoCircleOutlined />
                    </Tooltip>
                  </span>
                </div>
                <div className="desc-td">{clickedPId.p_recruitment.toFixed(2)}</div>
              </div>
              <div className="score-tbl-td">
                <div className="title-td">Recruitment Factor Index &nbsp;
                  <span>
                    <Tooltip title="Derived based on the range of Indication Recruitment Factor">
                      <InfoCircleOutlined />
                    </Tooltip>
                  </span>
                </div>
                <div className="desc-td">{clickedPId.recruitment_factor_index}</div>
              </div>
              <div className="score-tbl-td">
                <div className="title-td">Start-up Factor &nbsp;
                  <span>
                    <Tooltip title="Mean value of ‘Site Startup Fctr SC Avg’ for PI where 'Site_Activated'=='Yes'">
                      <InfoCircleOutlined />
                    </Tooltip>
                  </span>
                </div>
                <div className="desc-td">{clickedPId?.p_start_up_factor.toFixed(2)}</div>
              </div>
              <div className="score-tbl-td">
                <div className="title-td">Startup index &nbsp;
                  <span>
                    <Tooltip title="Derived based on the range of Indication Start-Up Factor ">
                      <InfoCircleOutlined />
                    </Tooltip>
                  </span>
                </div>
                <div className="desc-td">{clickedPId.startup_index}</div>
              </div>
              <div className="score-tbl-td">
                <div className="title-td">Study Experience Index &nbsp;
                  <span>
                    <Tooltip title="Derived based on the range of Industry Studies">
                      <InfoCircleOutlined />
                    </Tooltip>
                  </span>
                </div>
                <div className="desc-td">{clickedPId.industryStudyIndex}</div>
              </div>
              <div className="score-tbl-td">
                <div className="title-td">PI ranking &nbsp;
                  <span>
                    <Tooltip title="Weighted average of ‘Recruitment Factor Index', ‘Start Up Index’ and 'Study Experience Index">
                      <InfoCircleOutlined />
                    </Tooltip>
                  </span>
                </div>
                <div className="desc-td">{clickedPId?.pi_ranking ? clickedPId?.pi_ranking.toFixed(2) : ''}</div>
              </div>
            </div>
            {/* <div className="score-tbl">
              <div className="score-tbl-td">
                <div className="title-td"># Industry studies:
                </div>
                <div className="desc-td">{clickedPId.p_industry_studies}</div>
              </div>
              <div className="score-tbl-td">
                <div className="title-td"># of PXL Studies:</div>
                <div className="desc-td">{clickedPId.pxl_studies}</div>
              </div>
              <div className="score-tbl-td">
                <div className="title-td">Recruitment index:</div>
                <div className="desc-td">{clickedPId.recruitment_factor_index}</div>
              </div>
              <div className="score-tbl-td">
                <div className="title-td">Start-up index:</div>
                <div className="desc-td">{clickedPId.startup_index}</div>
              </div>
              <div className="score-tbl-td">
                <div className="title-td">
                  Indication Recruitment Factor (Avg Site Recruitment Fctr SC):
                </div>
                <div className="desc-td">{clickedPId.p_recruitment}</div>
              </div>
              <div className="score-tbl-td">
                <div className="title-td">
                  Indication Start-Up Factor (Avg of Site Startup Fctr SC):
                </div>
                <div className="desc-td">{clickedPId.p_start_up}</div>
              </div>
              <div className="score-tbl-td">
                <div className="title-td">Study Experience Index:</div>
                <div className="desc-td">{clickedPId.industryStudyIndex}</div>
              </div>
              <div className="score-tbl-td">
                <div className="title-td">PI ranking:</div>
                <div className="desc-td">{clickedPId.pi_ranking}</div>
              </div>
            </div> */}
          </>
        ) : flag === 'profile-tier' ? (
          <>
            <div className="score-tbl-th"> Profile Tier :</div>
            <div className="score-tbl">
              <div className="score-tbl-td">
                <div className="title-td"># Industry studies  &nbsp;
                  <span>
                    <Tooltip title="# Industry studies">
                      <InfoCircleOutlined />
                    </Tooltip>
                  </span>
                </div>
                <div className="desc-td">{clickedPId?.profile_tier?.industry_studies}</div>
              </div>
              <div className="score-tbl-td">
                <div className="title-td"># of PXL Studies  &nbsp;
                  <span>
                    <Tooltip title="# PXL Studies">
                      <InfoCircleOutlined />
                    </Tooltip>
                  </span>
                </div>
                <div className="desc-td">{clickedPId?.profile_tier?.pxl_studies}</div>
              </div>

              <div className="score-tbl-td">
                <div className="title-td">Recruitment Factor &nbsp;
                  <span>
                    <Tooltip title="Mean value of 'Site Recruitment Fctr SC Avg' for PI where 'Site_Activated'=='Yes'">
                      <InfoCircleOutlined />
                    </Tooltip>
                  </span>
                </div>
                <div className="desc-td"> {clickedPId?.profile_tier?.indication_recruitment_factor ? clickedPId?.profile_tier?.indication_recruitment_factor.toFixed(2) : '-'}</div>
              </div>

              <div className="score-tbl-td">
                <div className="title-td">Recruitment Factor Index &nbsp;
                  <span>
                    <Tooltip title="Mean value of 'Site Recruitment Fctr SC Avg' for PI where 'Site_Activated'=='Yes'">
                      <InfoCircleOutlined />
                    </Tooltip>
                  </span>
                </div>
                <div className="desc-td">{clickedPId?.profile_tier?.recruitment_factor_index}</div>
              </div>

              <div className="score-tbl-td">
                <div className="title-td">Start-up Factor &nbsp;
                  <span>
                    <Tooltip title="Mean value of ‘Site Startup Fctr SC Avg’ for PI where 'Site_Activated'=='Yes'">
                      <InfoCircleOutlined />
                    </Tooltip>
                  </span>
                </div>
                <div className="desc-td"> {clickedPId?.profile_tier?.site_startup_factor_score_average?.toFixed(2)}</div>
              </div>
              <div className="score-tbl-td">
                <div className="title-td">Start-up index  &nbsp;
                  <span>
                    <Tooltip title="Derived based on the range of Indication Recruitment Factor">
                      <InfoCircleOutlined />
                    </Tooltip>
                  </span>
                </div>
                <div className="desc-td">{clickedPId?.profile_tier?.startup_index}</div>
              </div>
              {/* <div className="score-tbl-td"><div className="title-td">Indication Recruitment Factor (Avg Site Recruitment Fctr SC):</div><div className="desc-td">{clickedPId?.profile_tier?.p_recruitment}</div></div> */}
              {/* <div className="score-tbl-td">
                <div className="title-td">
                  Indication Start-Up Factor (Avg of Site Startup Fctr SC):
                </div>
                <div className="desc-td">

                </div>
              </div> */}
              <div className="score-tbl-td">
                <div className="title-td">Study Experience Index  &nbsp;
                  <span>
                    <Tooltip title="Derived based on the range of Industry Studies">
                      <InfoCircleOutlined />
                    </Tooltip>
                  </span>
                </div>
                <div className="desc-td">
                  {clickedPId?.profile_tier?.study_experience_index
                    ? clickedPId?.profile_tier?.study_experience_index
                    : '-'}
                </div>
              </div>
              <div className="score-tbl-td">
                <div className="title-td">
                  {clickedPId?.profile_tier && 'pi_ranking' in clickedPId?.profile_tier ? 'PI ranking' : 'Center ranking'}  &nbsp;
                  <span>
                    <Tooltip title="Weighted average of ‘Recruitment Factor Index', ‘Start Up Index’ and 'Study Experience Index">
                      <InfoCircleOutlined />
                    </Tooltip>
                  </span>
                </div>
                <div className="desc-td">
                  {
                    onProfileTierValue(clickedPId)
                  }
                </div>
              </div>
            </div>
          </>
        ) : flag === 'pi-tier' ? (
          <>
            <div className="score-tbl-th"> Tier :</div>
            <div className="score-tbl">
              <div className="score-tbl-td">
                <div className="title-td"># Industry studies &nbsp;
                  <span>
                    <Tooltip title="# Industry studies">
                      <InfoCircleOutlined />
                    </Tooltip>
                  </span>
                </div>
                <div className="desc-td">{data.p_industry_studies}</div>
              </div>
              <div className="score-tbl-td">
                <div className="title-td"># PXL Studies &nbsp;
                  <span>
                    <Tooltip title="# PXL Studies">
                      <InfoCircleOutlined />
                    </Tooltip>
                  </span>
                </div>
                <div className="desc-td">{data.parexel_studies}</div>
              </div>
              <div className="score-tbl-td">
                <div className="title-td">Indication Recruitment Factor &nbsp;
                  <span>
                    <Tooltip title="Mean value of 'Site Recruitment Fctr SC Avg' for PI where 'Site_Activated'=='Yes'">
                      <InfoCircleOutlined />
                    </Tooltip>
                  </span>
                </div>
                <div className="desc-td">{data.indication_recruitment_factor.toFixed(2)}</div>
              </div>
              <div className="score-tbl-td">
                <div className="title-td">Start-up Factor &nbsp;
                  <span>
                    <Tooltip title="Mean value of ‘Site Startup Fctr SC Avg’ for PI where 'Site_Activated'=='Yes'">
                      <InfoCircleOutlined />
                    </Tooltip>
                  </span>
                </div>
                <div className="desc-td">{data?.startup_factor_average?.toFixed(2)}</div>
              </div>
              <div className="score-tbl-td">
                <div className="title-td">Recruitment Factor Index &nbsp;
                  <span>
                    <Tooltip title="Derived based on the range of Indication Recruitment Factor">
                      <InfoCircleOutlined />
                    </Tooltip>
                  </span>
                </div>
                <div className="desc-td">{data.p_recruitment}</div>
              </div>
              <div className="score-tbl-td">
                <div className="title-td">Startup index &nbsp;
                  <span>
                    <Tooltip title="Derived based on the range of Indication Start-Up Factor ">
                      <InfoCircleOutlined />
                    </Tooltip>
                  </span>
                </div>
                <div className="desc-td">{data.p_start_up}</div>
              </div>
              <div className="score-tbl-td">
                <div className="title-td">Study Experience Index &nbsp;
                  <span>
                    <Tooltip title="Derived based on the range of Industry Studies">
                      <InfoCircleOutlined />
                    </Tooltip>
                  </span>
                </div>
                <div className="desc-td">{data.study_exp_index}</div>
              </div>
              <div className="score-tbl-td">
                <div className="title-td">PI ranking &nbsp;
                  <span>
                    <Tooltip title="Weighted average of ‘Recruitment Factor Index', ‘Start Up Index’ and 'Study Experience Index">
                      <InfoCircleOutlined />
                    </Tooltip>
                  </span>
                </div>
                <div className="desc-td">{data.pi_ranking.toFixed(2)}</div>
              </div>
            </div>
          </>
        ) : flag === 'site-tier' ? (
          <>
            <div className="score-tbl-th"> Tier :</div>
            <div className="score-tbl">
              <div className="score-tbl-td">
                <div className="title-td"># Industry studies &nbsp;
                  <span>
                    <Tooltip title="# Industry studies">
                      <InfoCircleOutlined />
                    </Tooltip>
                  </span>
                </div>
                <div className="desc-td">{data.p_industry_studies}</div>
              </div>
              <div className="score-tbl-td">
                <div className="title-td"># PXL Studies &nbsp;
                  <span>
                    <Tooltip title="# PXL Studies">
                      <InfoCircleOutlined />
                    </Tooltip>
                  </span>
                </div>
                <div className="desc-td">{data.parexel_studies}</div>
              </div>
              <div className="score-tbl-td">
                <div className="title-td">Indication Recruitment Factor &nbsp;
                  <span>
                    <Tooltip title="Mean value of 'Site Recruitment Fctr SC Avg' for PI where 'Site_Activated'=='Yes'">
                      <InfoCircleOutlined />
                    </Tooltip>
                  </span>
                </div>
                <div className="desc-td">{data?.indication_recruitment_factor?.toFixed(2)}</div>
              </div>
              <div className="score-tbl-td">
                <div className="title-td">Start-up Factor &nbsp;
                  <span>
                    <Tooltip title="Mean value of ‘Site Startup Fctr SC Avg’ for PI where 'Site_Activated'=='Yes'">
                      <InfoCircleOutlined />
                    </Tooltip>
                  </span>
                </div>
                <div className="desc-td">{data?.startup_average.toFixed(2)}</div>
              </div>
              <div className="score-tbl-td">
                <div className="title-td">Recruitment Factor Index &nbsp;
                  <span>
                    <Tooltip title="Derived based on the range of Indication Recruitment Factor">
                      <InfoCircleOutlined />
                    </Tooltip>
                  </span>
                </div>
                <div className="desc-td">{data.p_recruitment}</div>
              </div>
              <div className="score-tbl-td">
                <div className="title-td">Startup index &nbsp;
                  <span>
                    <Tooltip title="Derived based on the range of Indication Start-Up Factor ">
                      <InfoCircleOutlined />
                    </Tooltip>
                  </span>
                </div>
                <div className="desc-td">{data.p_start_up}</div>
              </div>
              <div className="score-tbl-td">
                <div className="title-td">Study Experience Index &nbsp;
                  <span>
                    <Tooltip title="Derived based on the range of Industry Studies">
                      <InfoCircleOutlined />
                    </Tooltip>
                  </span>
                </div>
                <div className="desc-td">{data.study_exp_index}</div>
              </div>
              <div className="score-tbl-td">
                <div className="title-td">SITE ranking: &nbsp;
                  <span>
                    <Tooltip title="Weighted average of ‘Recruitment Factor Index', ‘Start Up Index’ and 'Study Experience Index">
                      <InfoCircleOutlined />
                    </Tooltip>
                  </span>
                </div>
                <div className="desc-td">{data?.site_ranking?.toFixed(2)}</div>
              </div>
            </div>
          </>
        ) : flag === 'pi-reliability' ? (
          <>
            <div className="score-tbl-th"> Reliability Score :</div>
            <div className="score-tbl">
              <div className="score-tbl-td">
                <div className="title-td">Subjects entered treatment count :</div>
                <div className="desc-td">{data.p_setc}</div>
              </div>
              <div className="score-tbl-td">
                <div className="title-td">Subjects planned treatment count :</div>
                <div className="desc-td">{data.p_spet}</div>
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="score-tbl-th">Reliability Score : </div>
            <div className="score-tbl">
              <div className="score-tbl-td">
                <div className="title-td">Subjects entered treatment count :</div>
                <div className="desc-td">{clickedPId.p_setc}</div>
              </div>
              <div className="score-tbl-td">
                <div className="title-td">Subjects planned treatment count :</div>
                <div className="desc-td">{clickedPId.p_spet}</div>
              </div>
            </div>
          </>
        )}
      </Modal>
    </>
  );
}
