import {
  map, catchError, filter, mergeMap, takeUntil, delay,
} from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { ajax } from 'rxjs/ajax';
import { of } from 'rxjs';
import { apiCall } from '../../../utils';

export const GENERIC_ACTION = 'GENERIC_ACTION'

const GET_COUNTS_DATA = 'GET_COUNTS_DATA'
const GET_COUNTS_DATA_SUCCESS = 'GET_COUNTS_DATA_SUCCESS'
const GET_COUNTS_DATA_FAILURE = 'GET_COUNTS_DATA_FAILURE'

const GET_DATASET_LIST_DATA = 'GET_DATASET_LIST_DATA'
const GET_MORE_DATASET_LIST_DATA = 'GET_MORE_DATASET_LIST_DATA'
const GET_DATASET_LIST_DATA_SUCCESS = 'GET_DATASET_LIST_DATA_SUCCESS'
const GET_MORE_DATASET_LIST_DATA_SUCCESS = 'GET_MORE_DATASET_LIST_DATA_SUCCESS'
const GET_DATASET_LIST_DATA_FAILURE = 'GET_DATASET_LIST_DATA_FAILURE'
const GET_DATASET_LIST_RESET = 'GET_DATASET_LIST_RESET'

const GET_SEARCH_RESULT_FILTERS = 'GET_SEARCH_RESULT_FILTERS';
const GET_SEARCH_RESULT_FILTERS_SUCCESS = 'GET_SEARCH_RESULT_FILTERS_SUCCESS';
const GET_SEARCH_RESULT_FILTERS_RESET = 'GET_SEARCH_RESULT_FILTERS_RESET';
const GET_SEARCH_RESULT_FILTERS_FAILURE = 'GET_SEARCH_RESULT_FILTERS_FAILURE';

const GET_MAP_COORDINATES = 'GET_MAP_COORDINATES';
const GET_MAP_COORDINATES_SUCCESS = 'GET_MAP_COORDINATES_SUCCESS';
const GET_MAP_COORDINATES_RESET = 'GET_MAP_COORDINATES_RESET';
const GET_MAP_COORDINATES_FAILURE = 'GET_MAP_COORDINATES_FAILURE';

const GET_MAP_LIST_DATA = 'GET_MAP_LIST_DATA'
const GET_MAP_LIST_DATA_SUCCESS = 'GET_MAP_LIST_DATA_SUCCESS'
const GET_MAP_LIST_DATA_FAILURE = 'GET_MAP_LIST_DATA_FAILURE'
const GET_MAP_LIST_DATA_RESET = 'GET_MAP_LIST_DATA_RESET'

const GET_SEARCH_FIELDS_FILTERS = 'GET_SEARCH_FIELDS_FILTERS';
const GET_SEARCH_FIELDS_FILTERS_SUCCESS = 'GET_SEARCH_FIELDS_FILTERS_SUCCESS';
const GET_SEARCH_FIELDS_FILTERS_FAILURE = 'GET_SEARCH_FIELDS_FILTERS_FAILURE';

const INITIAL_STATE = {
  data: {},
  loading: false,
  error: false,
  flag: false,
}

export const getCountsDataAction = (payload, meta = {}) => ({
  type: GENERIC_ACTION,
  payload,
  meta: {
    actionType: GET_COUNTS_DATA,
    url: `${process.env.apiUrl}v0/search/count`,
    method: 'get',
    auth: true,
    log: false,
    ...meta,
  },
  showNotification: false,
  hideNotification: false,
})

export const getMapCoordinatesAction = (payload, meta = {}) => ({
  type: GENERIC_ACTION,
  payload,
  meta: {
    actionType: GET_MAP_COORDINATES,
    url: `${process.env.apiUrl}v0/search/coordinates`,
    method: 'get',
    auth: true,
    log: false,
    ...meta,
  },
  showNotification: false,
  hideNotification: false,
})

export const getMapCoordinatesResetAction = () => ({
  type: GET_MAP_COORDINATES_RESET,
})

export const getDatasetListResetAction = () => {
  return ({
    type: GET_DATASET_LIST_RESET,
  })
}

export const getDatasetListDataAction = (payload, meta = {}) => ({
  type: GENERIC_ACTION,
  payload,
  meta: {
    actionType: GET_DATASET_LIST_DATA,
    url: `${process.env.apiUrl}v0/search/list`,
    method: 'get',
    auth: true,
    log: false,
    ...meta,
  },
  showNotification: false,
  hideNotification: false,
})

export const getMapListDataAction = (payload, meta = {}) => ({
  type: GENERIC_ACTION,
  payload,
  meta: {
    actionType: GET_MAP_LIST_DATA,
    url: `${process.env.apiUrl}v0/search/list`,
    method: 'get',
    auth: true,
    log: false,
    ...meta,
  },
  showNotification: false,
  hideNotification: false,
})

export const getMoreDatasetListDataAction = (payload, meta = {}) => ({
  type: GENERIC_ACTION,
  payload,
  meta: {
    actionType: GET_MORE_DATASET_LIST_DATA,
    url: `${process.env.apiUrl}v0/search/list`,
    method: 'get',
    auth: true,
    log: false,
    ...meta,
  },
  showNotification: false,
  hideNotification: false,
})

export const genericEpic = (actions$) => actions$.pipe(
  ofType(GENERIC_ACTION),
  mergeMap((action) => of({
    type: `${action.meta.actionType}_CANCEL${action.meta.key ? `_${action.meta.key}` : ''}`,
    payload: action.payload,
    meta: action.meta,
    origin: 'generic',
  }, {
    type: action.meta.actionType,
    payload: action.payload,
    meta: action.meta,
    origin: 'generic',
    message: action.message,
    showNotification: action.showNotification,
    hideNotification: action.hideNotification,
    dummyResponse: action.dummyResponse,
    customErrMessage: action.customErrMessage,
  })),
);

export const apiCallEpic = (actions$) => actions$.pipe(
  filter((action) => action.origin === 'generic' && action.type.indexOf('_CANCEL') === -1),
  mergeMap((action) => {
    if (action.dummyResponse) {
      return of({
        type: `${action.meta.actionType}_SUCCESS`,
        payload: action.dummyResponse,
        meta: action.meta,
      }).pipe(
        delay(700),
      )
    }
    return ajax(apiCall(action.meta.url, action.meta.method, action.payload, action.meta.auth, action.meta.customHeaders))
      .pipe(
        map((res) => ({
          type: `${action.meta.actionType}_SUCCESS`,
          payload: res.response,
          meta: action.meta,
          showNotification: action.showNotification,
          message: action.message,
        })),
        takeUntil(actions$.pipe(ofType(`${action.meta.actionType}_CANCEL${action.meta.key ? `_${action.meta.key}` : ''}`))),
        catchError((err) => of({
          type: `${action.meta.actionType}_FAILURE`,
          payload: err,
          meta: action.meta,
          hideNotification: action.hideNotification,
          message: action.customErrMessage,
        })),
      )
  }),
);

export const getSearchResultFilterAction = (payload, meta = {}) => ({
  type: GENERIC_ACTION,
  payload,
  meta: {
    actionType: GET_SEARCH_RESULT_FILTERS,
    url: `${process.env.apiUrl}v0/search/searchfilters`,
    method: 'get',
    auth: true,
    log: false,
    ...meta,
  },
  showNotification: false,
  hideNotification: false,
})

export const getSearchResultFilterResetAction = () => ({
  type: GET_SEARCH_RESULT_FILTERS_RESET,
})

export function getCountsDataReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case GET_COUNTS_DATA: {
      return {
        data: {},
        loading: true,
        flag: false,
        error: false,
      }
    }
    case GET_COUNTS_DATA_SUCCESS: {
      return {
        data: action.payload,
        loading: false,
        flag: true,
        error: false,
      }
    }
    case GET_COUNTS_DATA_FAILURE: {
      return {
        data: {},
        loading: false,
        flag: false,
        error: true,
      }
    }
    default:
      return state
  }
}

export function getMapCoordinatesReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case GET_MAP_COORDINATES: {
      return {
        data: {},
        loading: true,
        flag: false,
        error: false,
      }
    }
    case GET_MAP_COORDINATES_SUCCESS: {
      return {
        data: action.payload,
        loading: false,
        flag: true,
        error: false,
      }
    }
    case GET_MAP_COORDINATES_FAILURE: {
      return {
        data: {},
        loading: false,
        flag: false,
        error: true,
      }
    }
    case GET_MAP_COORDINATES_RESET: {
      return {
        data: {},
        loading: false,
        flag: false,
        error: false,
      }
    }
    default:
      return state
  }
}

export function getMapListDataReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case GET_MAP_LIST_DATA: {
      return {
        data: {},
        loading: true,
        flag: false,
        error: false,
      }
    }
    case GET_MAP_LIST_DATA_SUCCESS: {
      return {
        data: action.payload,
        loading: false,
        flag: true,
        error: false,
      }
    }
    case GET_MAP_LIST_DATA_FAILURE: {
      return {
        data: {},
        loading: false,
        flag: false,
        error: true,
      }
    }
    case GET_MAP_LIST_DATA_RESET: {
      return {
        data: {},
        loading: false,
        flag: false,
        error: false,
      }
    }
    default:
      return state
  }
}

export function getDatasetListDataReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case GET_DATASET_LIST_DATA: {
      return {
        data: {},
        loading: true,
        flag: false,
        error: false,
      }
    }
    case GET_DATASET_LIST_DATA_SUCCESS: {
      return {
        data: action.payload,
        loading: false,
        flag: true,
        error: false,
      }
    }
    case GET_MORE_DATASET_LIST_DATA_SUCCESS: {
      return {
        data: {
          data: [
            ...state.data.data,
            ...action.payload.data,
          ],
          total: action.payload.total,
        },
        loading: false,
        flag: true,
        error: false,
      }
    }
    case GET_DATASET_LIST_DATA_FAILURE: {
      return {
        data: {},
        loading: false,
        flag: false,
        error: true,
      }
    }
    case GET_DATASET_LIST_RESET: {
      return {
        data: {},
        loading: false,
        flag: false,
        error: false,
      }
    }
    default:
      return state
  }
}

export function getSearchResultFilterReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case GET_SEARCH_RESULT_FILTERS: {
      return {
        data: {
          ...state.data,
        },
        loading: true,
        flag: false,
        error: false,
      }
    }
    case GET_SEARCH_RESULT_FILTERS_SUCCESS: {
      if (action.meta.filter_type) {
        return {
          data: {
            ...state.data, [action.meta.filter_type]: action.payload,
          },
          loading: false,
          flag: true,
          error: false,
        }
      }
      return {
        data: action.payload,
        loading: false,
        flag: true,
        error: false,
      }
    }
    case GET_SEARCH_RESULT_FILTERS_RESET: {
      return {
        data: {},
        loading: false,
        flag: false,
        error: false,
      }
    }
    case GET_SEARCH_RESULT_FILTERS_FAILURE: {
      return {
        data: {},
        loading: false,
        flag: false,
        error: true,
      }
    }
    default:
      return state
  }
}

export const getSearchFieldsFilterAction = (payload, meta = {}) => ({
  type: GENERIC_ACTION,
  payload,
  meta: {
    actionType: GET_SEARCH_FIELDS_FILTERS,
    url: `${process.env.apiUrl}v0/search/searchfilters`,
    method: 'get',
    auth: true,
    log: false,
    ...meta,
  },
  showNotification: false,
  hideNotification: false,
})

export function getSearchFieldsFilterReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case GET_SEARCH_FIELDS_FILTERS: {
      return {
        data: [],
        loading: true,
        flag: false,
        error: false,
      }
    }
    case GET_SEARCH_FIELDS_FILTERS_SUCCESS: {
      if (action.meta.filter_type) {
        return {
          data: action.payload,
          loading: false,
          flag: true,
          error: false,
        }
      }
      return {
        data: action.payload,
        loading: false,
        flag: true,
        error: false,
      }
    }
    case GET_SEARCH_FIELDS_FILTERS_FAILURE: {
      return {
        data: [],
        loading: false,
        flag: false,
        error: true,
      }
    }
    default:
      return state
  }
}
